import * as Sentry from '@sentry/vue'

export const handleError = (error: any) => {
    if (process.env.NODE_ENV === 'test') return
    if (process.env.VUE_APP_ROOT_DOMAIN === '.bdh.test') {
        console.error(error) // eslint-disable-line
    } else {
        Sentry.captureException(error)
    }
}
