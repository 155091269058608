const authMiddleware = {
    en_GB: {
        accountVerified: 'Your account has been verified!',
        sessionExpired: 'Your active session has expired. Please log in.',
        autoLogout: 'You have been automatically logged out from your active session.'
    },
    en_US: {
        accountVerified: 'Your account has been verified!',
        sessionExpired: 'Your active session has expired. Please log in.',
        autoLogout: 'You have been automatically logged out from your active session.'
    },
    es_MX: {
        accountVerified: 'Su sesión activa ha caducado. Por favor Iniciar sesión.',
        sessionExpired: 'Tu cuenta ha sido verificada!',
        autoLogout: 'Se ha desconectado automáticamente de su sesión activa.'
    }
}

export default authMiddleware
