const login = {
    en_GB: {
        backTo: 'Back to Log In',
        logInTo: 'Log in to {appName}'
    },
    en_US: {
        backTo: 'Back to Log In',
        logInTo: 'Log in to {appName}'
    },
    es_MX: {
        backTo: 'Atrás para iniciar sesión',
        logInTo: 'Iniciar sesión en {appName}'
    }
}

export default login
