const rules = {
    en_GB: {
        addressFilled: 'An address is required with a ZIP or postcode',
        digits: '{length} digits required',
        alphaNum: 'Alphabetic characters or numbers',
        length: '{length} characters required',
        maxLength: 'Max {length} characters',
        minLength: 'Min {length} characters',
        passwordRequirements:
            'Must use 10 or more lowercase and uppercase characters, and include at least one number or symbol.',
        required: 'Required',
        isRequired: '{field} is required',
        thisField: 'This field',
        optional: 'Optional',
        isOptional: '{field} is optional',
        validEmail: 'Please enter a valid email address',
        validClia: 'Please enter a valid CLIA number. Example: 12D345678',
        zipHintUS: 'The code provided must be a valid US Zip code',
        zipHintUK: 'The code provided must be a valid UK or Irish postal code',
        zipFilled: 'A valid ZIP or postcode is required with an address',
        useDateFormat: 'Use date format',
        noBeforeYears: 'Date must be after {number} years ago',
        noBeforeNow: 'Date cannot be later than today'
    },
    en_US: {
        addressFilled: 'An address is required with a ZIP or postcode',
        digits: '{length} digits required',
        alphaNum: 'Alphabetic characters or numbers',
        length: '{length} characters required',
        maxLength: 'Max {length} characters',
        minLength: 'Min {length} characters',
        passwordRequirements:
            'Must use 10 or more lowercase and uppercase characters, and include at least one number or symbol.',
        required: 'Required',
        isRequired: '{field} is required',
        optional: 'Optional',
        isOptional: '{field} is optional',
        validEmail: 'Please enter a valid email address',
        validClia: 'Please enter a valid CLIA number. Example: 12D345678',
        zipHintUS: 'The code provided must be a valid US Zip code',
        zipHintUK: 'The code provided must be a valid UK or Irish postal code',
        zipFilled: 'A valid ZIP or postcode is required with an address',
        useDateFormat: 'Use date format',
        noBeforeYears: 'Date must be after {number} years ago',
        noBeforeNow: 'Date cannot be later than today'
    },
    es_MX: {
        addressFilled: 'Se requiere una dirección con un código postal o postal',
        digits: '{length} dígitos requeridos',
        alphaNum: 'Caracteres alfabéticos o números',
        length: '{length} caracteres requeridos',
        maxLength: 'Max {length} caracteres',
        minLength: 'Mínimo {length} caracteres',
        passwordRequirements:
            'Debe usar 10 o más caracteres en minúsculas y mayúsculas, e incluir al menos un número o símbolo.',
        required: 'Obligatorio',
        isRequired: '{field} es obligatorio',
        thisField: 'Este campo de entrada',
        optional: 'Opcional',
        isOptional: '{field} es opcional',
        validEmail: 'Por favor, introduce una dirección de correo electrónico válida',
        validClia: 'Ingrese un número CLIA válido. Ejemplo: 12D345678',
        zipHintUS: 'El código proporcionado debe ser un código postal válido de EE. UU.',
        zipHintUK: 'El código proporcionado debe ser un código postal válido del Reino Unido o irlandés',
        zipFilled: 'Se requiere un código postal válido o postal con una dirección',
        useDateFormat: 'Usar formato de fecha',
        noBeforeYears: 'La fecha debe ser posterior a hace {number} años.',
        noBeforeNow: 'La fecha no puede ser posterior a hoy'
    }
}

export default rules
