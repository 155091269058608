import { Store } from 'vuex'

export const setInitialLocale = async (store: Store<any>, vuetify: any, tenant: any) => {
    let queryLang = null

    if (!store.getters['authStore/isUserLoggedIn']) {
        const urlParams = new URLSearchParams(window.location.search)
        queryLang = urlParams.get('lang')
    }

    const defaultLanguage = tenant.getDefaultLanguage()

    vuetify.framework.lang.current = defaultLanguage.substring(0, 2)
    store.commit('setLocale', { locale: defaultLanguage })
}
