import { getInitialGuiState } from '@/store/initialState'
import { assign } from 'lodash'
import { GuiState } from '@/interfaces/state'
import http from '@/services/api/http'
import { get } from 'lodash'
import { handleError } from '@/helpers/error'

const gui = {
    namespaced: true,
    state: getInitialGuiState() as GuiState,
    mutations: {
        setDefaultRows(state: GuiState, numOfRows = 5) {
            state.defaultRows = numOfRows
        },
        setGlobalLoading(state: GuiState, isLoading: boolean) {
            state.globalLoading = isLoading
        },
        setNavDrawerOpen(state: GuiState, bool: boolean) {
            state.navDrawerOpen = bool
        },
        setInitialGuiState(state: any) {
            assign(state, getInitialGuiState() as GuiState)
        },
        setUsCounties(state: any, counties: string[]) {
            state.usCounties = counties
        }
    },
    getters: {
        defaultRows: (state: GuiState) => state.defaultRows,
        navDrawerOpen: (state: GuiState) => state.navDrawerOpen,
        globalLoading: (state: GuiState) => state.globalLoading,
        usCounties: (state: GuiState) => state.usCounties
    },
    actions: {
        async loadUsCounties(state: any) {
            const cached = state.usCounties
            if (cached && cached.length) return
            try {
                const response = await http.get('/assays/us-counties')
                if (response?.status === 200) state.commit('setUsCounties', get(response, 'data.data'))
            } catch (error) {
                handleError(error)
            }
        }
    }
}

export default gui
