import { map, first, get, endsWith } from 'lodash'
import store from '@/store'

export const handleForm = async (error: any) => {
    const url = get(error, 'response.config.url', '')
    const isDeletionRequest = endsWith(url, 'delete')
    const isSelfRegUserCreationRequest = url === '/self-registration-user-create'

    if (isDeletionRequest || isSelfRegUserCreationRequest) return Promise.reject(error.response)

    const errors = get(error, 'response.data.errors')
    const messages = map(errors, error => first(error))

    if (messages.length) store.commit('setValidationEvent', messages)
}
