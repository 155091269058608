import axios, { CancelTokenSource, CancelTokenStatic } from 'axios'

class CancelToken {
    private static instance: CancelToken

    private httpCancelToken: CancelTokenStatic

    private httpSource: CancelTokenSource

    private constructor() {
        this.httpCancelToken = axios.CancelToken
        this.httpSource = axios.CancelToken.source()
    }

    public static getInstance(): CancelToken {
        if (!CancelToken.instance) {
            CancelToken.instance = new CancelToken()
        }

        return CancelToken.instance
    }

    public getHttpToken() {
        return this.httpSource.token
    }

    public abortHttp() {
        this.httpSource.cancel()
    }

    public resetHttp() {
        this.httpSource = this.httpCancelToken.source()
    }
}

export default CancelToken
