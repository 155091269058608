const input = {
    en_GB: {
        enterReason: 'Enter your reason',
        selectReason: 'Select a reason'
    },
    en_US: {
        enterReason: 'Enter your reason',
        selectReason: 'Select a reason'
    },
    es_MX: {
        enterReason: 'Seleccione una razón',
        selectReason: 'Ingrese su razón'
    }
}

export default input
