<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <defs>
            <path id="tmk51uolza" d="M0 0H24V24H0z" />
            <path id="3kae1vvmfb" d="M4 16h12v2H4v-2zm0-5h14v2H4v-2zm0-5h16v2H4V6z" />
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g>
                <g transform="translate(-9 -9) translate(9 9)">
                    <mask id="aoj9sazlpc" fill="#fff">
                        <use xlink:href="#3kae1vvmfb" />
                    </mask>
                    <g fill="currentColor" fill-rule="nonzero" mask="url(#aoj9sazlpc)">
                        <path d="M0 0L42 0 42 42 0 42z" transform="translate(-9 -9)" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'HamburgerIcon'
}
</script>

<style scoped></style>
