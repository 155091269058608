import { Invite } from '@/interfaces/resources'
import modal from '@/store/modules/modal'
import { assign } from 'lodash'
import { getInitialInviteState } from '@/store/initialState'

const inviteStore = {
    namespaced: true,
    state: getInitialInviteState(),
    mutations: {
        setItem(state: any, invite?: Invite) {
            state.invite = invite || null
        },
        setInitialInviteState(state: any) {
            assign(state, getInitialInviteState())
        },
        ...modal.mutations
    },
    getters: {
        getItem: (state: any) => state.invite,
        ...modal.getters
    }
}

export default inviteStore
