const dashboard = {
    path: '',
    name: 'dashboard',
    component: () => import('@/modules/dashboard/views/Dashboard.vue'),
    meta: {
        pageTitle: 'generic.dashboard'
    }
}

export default dashboard
