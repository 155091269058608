import { DateTime } from 'luxon'
import { email, max, required, digits, alpha_num, length, min, regex } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import { first, get, last } from 'lodash'
import { getDateInputFormatMessage, getDateInputFormat } from '@/helpers/tenant'
import i18n from '@/plugins/i18n'
import tenant from '@/services/tenant'
import { getNumberType, parsePhoneNumber } from 'libphonenumber-js'
import { phoneNumberHelper } from '@/helpers/phoneNumber'

const appPasswordRegex =
    /^(?:(?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^a-zA-Z0-9])(?=.*[a-z])|(?=.*[^a-zA-Z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^a-zA-Z0-9]))(?!.*\s).{10,128}$/

extend('required', {
    ...required,
    message(field) {
        return `${i18n.t('rules.isRequired', {
            field
        })}`
    }
})

extend('digits', {
    ...digits,
    message(field, args) {
        return `${i18n.t('rules.digits', {
            length: args.length
        })}`
    }
})

extend('alpha_num', {
    ...alpha_num,
    message() {
        return `${i18n.t('rules.alphaNum')}`
    }
})

extend('length', {
    ...length,
    message(field, args) {
        return `${i18n.t('rules.length', {
            length: args.length
        })}`
    }
})

extend('max', {
    ...max,
    message(field, args) {
        return `${i18n.t('rules.maxLength', {
            length: args.length
        })}`
    }
})

extend('min', {
    ...min,
    message(field, args) {
        return `${i18n.t('rules.minLength', {
            length: args.length
        })}`
    }
})

extend('clia', {
    validate: value => {
        return /^[A-Z, a-z, 0-9]{10}$/.test(value)
    },
    message() {
        return `${i18n.t('rules.validClia')}`
    }
})

extend('email', {
    ...email,
    message() {
        return `${i18n.t('rules.validEmail')}`
    }
})

extend('isValidPassword', {
    validate: value => {
        return appPasswordRegex.test(value)
    },
    message() {
        return `${i18n.t('rules.passwordRequirements')}`
    }
})

extend('isValidZipUS', {
    validate: value => /^[0-9]{5}(?:-[0-9]{4})?$/.test(value),
    message() {
        return `${i18n.t('generic.zipWarningUS')}`
    }
})

extend('isValidZipUK', {
    validate: value => /^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][ABD-HJLNP-UW-Z]{2}$/.test(value),
    message() {
        return `${i18n.t('generic.zipWarningUK')}`
    }
})

extend('isValidZipIRL', {
    validate: value =>
        /(?:^[AC-FHKNPRTV-Y,ac-fhknprtv-y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y,0-9ac-fhknprtv-y]{4}$/.test(value),
    message() {
        return `${i18n.t('generic.zipWarningIRL')}`
    }
})

extend('isZipFilled', {
    params: ['target'],
    validate: (value, { target }) => {
        return !!target
    },
    message() {
        return `${i18n.t('rules.zipFilled')}`
    }
})

extend('isAddressFilled', {
    params: ['target'],
    validate: (value, { target }) => {
        return !!target
    },
    message() {
        return `${i18n.t('rules.addressFilled')}`
    }
})

extend('dobDateFormat', {
    validate: (value, options) => {
        return DateTime.fromFormat(value, first(options)).isValid
    },
    message() {
        return `${i18n.t('rules.useDateFormat')} - ${getDateInputFormatMessage(tenant.getInstance())}`
    }
})

extend('dobMaxDate', {
    validate: value => {
        const dateToCompare = get(DateTime.fromFormat(value, getDateInputFormat(tenant.getInstance())), 'ts')
        const now = get(DateTime.now(), 'ts')

        return dateToCompare < now
    },
    message() {
        return `${i18n.t('rules.noBeforeNow')}`
    }
})

extend('dobMinDate', {
    validate: value => {
        const dateToCompare = get(DateTime.fromFormat(value, getDateInputFormat(tenant.getInstance())), 'ts')
        const minYears = get(DateTime.now().minus({ years: 120 }).startOf('day'), 'ts')

        return dateToCompare >= minYears
    },
    message() {
        return `${i18n.t('rules.noBeforeYears', { number: 120 })}`
    }
})

extend('isValidPhonePrefix', {
    validate: (value, options) => {
        const code = first(options)
        return !!code
    },
    message() {
        return `${i18n.t('validation.phoneNumber.error.code')}`
    }
})

extend('isValidPhone', {
    validate: (value, options) => {
        const code: string = first(options)
        if (!code) return false

        const { isValid } = phoneNumberHelper(code, value)

        return isValid
    },
    message(field) {
        return `${i18n.t(`validation.phoneNumber.error.${field}.invalid`)}`
    }
})

extend('isValidPhoneType', {
    validate: (value, options) => {
        const code: string = first(options)
        if (!code) return false

        const type = last(options)
        const { isValid, iso2 } = phoneNumberHelper(code, value)
        if (!isValid) return false

        const phoneNumber = parsePhoneNumber(value, iso2.toUpperCase())
        const phoneNumberType = getNumberType(phoneNumber.number as any)

        if (!phoneNumberType || [phoneNumberType, type].includes('FIXED_LINE_OR_MOBILE')) return true

        return phoneNumberType === type
    },
    message(field) {
        return `${i18n.t(`validation.phoneNumber.error.${field}.invalid`)}`
    }
})

extend('regex', regex)
