import { isValidPhoneNumber } from 'libphonenumber-js'

export const phoneNumberHelper = (code: string, phoneNumber: string) => {
    const [iso2, dCode]: any[] = code.split('_')
    const number = dCode ? dCode + phoneNumber : phoneNumber

    const isValid = isValidPhoneNumber(number, iso2)

    return {
        isValid: isValid,
        iso2: iso2
    }
}
