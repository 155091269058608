import { get } from 'lodash'
const envs = ['local', 'local-fe', 'develop', 'staging', 'qa']

/**
 * Determines if browser is IE11 by checking for MSIE and Trident in
 * userAgent and if found then returns false
 * @returns
 */
export const isSupportedBrowser = () => {
    const ua = get(window, 'navigator.userAgent')
    const msie = ua.indexOf('MSIE ') > 0
    const trident = ua.indexOf('Trident/') > 0

    if (msie || trident) return false
    else return true
}

/**
 * Uses the hostname to determine what environment the app is running in.
 * @param hostName
 * @returns
 */
const getEnvironment = (hostName?: string) => {
    const hostname = hostName ? hostName : get(window, 'location.hostname')
    return hostname.split('.').reduce((acc, cur) => {
        if (envs.includes(cur)) acc = cur
        return acc
    }, 'production')
}

/**
 * Optionally takes the hostname as and argument and returns the correct base
 * URL for the API. This allows for the webapp and API to run on different
 * subdomains when developing locally
 * @param hostName
 * @returns
 */
const getLocalApiUrl = (hostName?: string) => {
    const h = hostName ? hostName : get(window, 'location.hostname')
    return `http://${h.split('.')[0]}.local${process.env.VUE_APP_ROOT_DOMAIN}`
}

export { getEnvironment, getLocalApiUrl }
