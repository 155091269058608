const countries = {
    en_GB: {
        US: 'United States of America',
        IE: 'Republic of Ireland',
        GB: 'The United Kingdom of Great Britain and Northern Ireland'
    },
    en_US: {
        US: 'United States of America',
        IE: 'Republic of Ireland',
        GB: 'The United Kingdom of Great Britain and Northern Ireland'
    },
    es_MX: {
        US: 'Estados Unidos de América',
        ie: 'Republica de Irlanda',
        GB: 'Reino Unido de Gran Bretaña e Irlanda del Norte'
    }
}

export default countries
