import { filter, first, get, includes } from 'lodash'
import NavLink from '@/interfaces/navLink'
import { AuthState } from '@/interfaces/state'

export const getters = {
    isUserLoggedIn: (state: AuthState) => state.isUserLoggedIn,
    getPermissions: (state: AuthState) => get(state.loggedInUser, 'permissions', []),
    getTerms: (state: AuthState) => !!get(state.loggedInUser, 'termsAccepted', false),
    getUserType: (state: AuthState) => get(state.loggedInUser, 'userType.name'),
    getLoggedInUser: (state: AuthState) => state.loggedInUser,
    getLoggingOutComplete: (state: AuthState) => state.loggingOutComplete,
    getGroups: (state: AuthState) => get(state.loggedInUser, 'groups', []),
    getGroup: (state: AuthState, getters: any) => {
        if (getters.getGroups.length) return first(getters.getGroups)
    },
    getOrganisation: (state: AuthState) => get(state.loggedInUser, 'organisation'),
    getTenant: (state: AuthState) => get(state.loggedInUser, 'tenant'),
    getLang: (state: AuthState): string => get(state.loggedInUser, 'lang'),
    getUserTypes: (state: AuthState) => state.userTypes,
    hasAddedOwnTenant: (state: AuthState) => state.hasAddedOwnTenant,
    hasAddedOwnOrganisation: (state: AuthState) => state.hasAddedOwnOrganisation,
    hasAddedOwnGroup: (state: AuthState) => state.hasAddedOwnGroup,
    hasPermission: (state: AuthState, getters: any) => (permission: string) => {
        return includes(getters.getPermissions, permission)
    },
    getPermittedLinks:
        (state: AuthState, getters: any) =>
        (links: NavLink[], features: string[] = []) => {
            return filter(links, link => {
                let hasPermittedLink = includes(getters.getPermissions, `view-${link.listPermission}`)

                // if the link has feature flags associated with it then hide or show based on these
                if (link.featureFlags) {
                    const include = link.featureFlags.includes.filter(f => features.includes(f))
                    const exclude = link.featureFlags.excludes.filter(f => features.includes(f))
                    if (exclude.length) return false // if tenant feature flag exists in link exclude then hide
                    if (include.length) return true // if tenant feature flag exists in link exclude then show
                    // if niether then move on
                }

                if (!hasPermittedLink) {
                    hasPermittedLink =
                        link.viewPermission === 'create-tests' ||
                        includes(getters.getPermissions, `view-own-${link.viewPermission}`)
                    link.showList = !hasPermittedLink
                }

                return hasPermittedLink
            })
        }
}
