














import { defineComponent } from '@vue/composition-api'

import PageTitle from '@/modules/_global/components/misc/PageTitle.vue'

export default defineComponent({
    name: 'TitleBar',
    components: {
        PageTitle
    }
})
