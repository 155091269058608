const generic = {
    en_GB: {
        active: 'Active',
        address: 'Address',
        allResults: 'All Results',
        cancel: 'Cancel',
        checkInbox: 'Check your inbox!',
        checkSpam: 'You may need to check your spam folder, too.',
        city: 'City',
        clia: 'CLIA',
        clear: 'Clear',
        companyLogo: 'Company Logo',
        continue: 'Continue',
        country: 'Country',
        county: 'County',
        customId: 'Custom ID 1',
        secondCustomId: 'Custom ID 2',
        dashboard: 'Dashboard',
        dashboardLower: 'dashboard',
        date: 'Date',
        dateOfBirth: 'Date of Birth',
        day: 'Day',
        description: 'Description',
        done: 'Done',
        email: 'Email',
        emailAddress: 'Email address',
        from: 'From',
        futureDateTime: 'Please provide a future date and time',
        goTO: 'Go to {page}',
        group: 'Group',
        groupLink: 'Link to group',
        groupLower: 'group',
        groups: 'Groups',
        groupsLower: 'groups',
        invite: 'invite',
        invitedBy: 'Invited By',
        invitedWhen: 'Invited When',
        inviteHint: 'sent with invite email',
        inviteLabel: 'Invite code',
        invitePlaceholder: 'Enter your 24 character code',
        invites: 'Invites',
        invitesLower: 'invites',
        labName: 'Laboratory Name',
        lastLogin: 'Last login',
        linkToOrg: 'Link to org',
        linkToTenant: 'Link to tenant',
        location: 'Location',
        locationCoordinates: 'Location Coordinates',
        locations: 'Locations',
        logIn: 'Log In',
        logInLowerSecond: 'Log in',
        logInTo: 'Log in to {appName}',
        logout: 'Logout',
        phoneNumber: 'Phone number',
        month: 'Month',
        myGroup: 'My Group',
        myOrganisation: 'My Organisation',
        name: 'Name',
        npi: 'NPI',
        options: 'Options',
        organisation: 'Organisation',
        organisationLink: 'Link to individual organisation',
        organisationLower: 'organisation',
        organisations: 'Organisations',
        organisationsLower: 'organisations',
        password: 'Password',
        recordedBy: 'Recorded by',
        records: 'Records',
        recordTest: 'Record a test',
        region: 'Region',
        reset: 'Reset',
        result: 'Result',
        results: 'Results',
        save: 'Save',
        saveChanges: 'Save changes',
        selectDate: 'Select Date',
        selectTime: 'Select Time',
        signUpNow: 'Sign Up Now',
        status: 'Status',
        streetAddress: 'Street Address',
        streetAddress2: 'Street Address 2',
        subject: 'Test Subject',
        subjectLink: 'Link to test subject',
        submit: 'Submit',
        submitTest: 'Submit Test',
        suspend: 'Suspend',
        suspended: 'Suspended',
        suspendedLower: 'suspended',
        tenant: 'Tenant',
        tenantLink: 'Link to individual tenant',
        tenantLower: 'tenant',
        tenants: 'Tenants',
        tenantsLower: 'tenants',
        test: 'Test',
        testID: 'Test ID',
        testInitial: 'T',
        testLink: 'Link to individual test record',
        testLocation: 'Test Location',
        testLower: 'test',
        testRecord: 'Test Record',
        testRecords: 'Test Records',
        tests: 'Tests',
        testsLower: 'tests',
        time: 'Time',
        to: 'To',
        total: 'Total',
        totalTests: 'Total Tests',
        unknown: 'unknown',
        unknownError: 'An unknown error has occurred',
        user: 'User',
        userLink: 'Link to individual user',
        userLower: 'user',
        users: 'Users',
        usersLower: 'users',
        userType: 'User Type',
        viewIn: 'Ver en',
        year: 'Year',
        zip: 'ZIP',
        zipLabelUK: 'Postcode',
        zipLabelUS: 'ZIP code',
        zipPlaceholderIRL: 'Enter an Irish Postcode',
        zipPlaceholderUK: 'Enter a UK Postcode',
        zipPlaceholderUS: 'Enter a ZIP code',
        zipWarningIRL: 'Please enter a valid Irish Postcode',
        zipWarningUK: 'Please enter a valid UK Postcode',
        zipWarningUS: 'Please enter a valid US Zip Code',
        error: 'error'
    },
    en_US: {
        active: 'Active',
        address: 'Address',
        allResults: 'All Results',
        cancel: 'Cancel',
        checkInbox: 'Check your inbox!',
        checkSpam: 'You may need to check your spam folder, too.',
        city: 'City',
        clear: 'Clear',
        clia: 'CLIA',
        companyLogo: 'Company Logo',
        continue: 'Continue',
        country: 'Country',
        county: 'County',
        customId: 'Custom ID 1',
        secondCustomId: 'Custom ID 2',
        dashboard: 'Dashboard',
        dashboardLower: 'dashboard',
        date: 'Date',
        dateOfBirth: 'Date of Birth',
        day: 'Day',
        description: 'Description',
        done: 'Done',
        email: 'Email',
        emailAddress: 'Email address',
        from: 'From',
        futureDateTime: 'Please provide a future date and time',
        goTO: 'Go to {page}',
        group: 'Group',
        groupLink: 'Link to group',
        groupLower: 'group',
        groups: 'Groups',
        groupsLower: 'groups',
        invite: 'invite',
        invitedBy: 'Invited By',
        invitedWhen: 'Invited When',
        inviteHint: 'sent with invite email',
        inviteLabel: 'Invite code',
        invitePlaceholder: 'Enter your 24 character code',
        invites: 'Invites',
        invitesLower: 'invites',
        labName: 'Laboratory Name',
        lastLogin: 'Last login',
        linkToOrg: 'Link to org',
        linkToTenant: 'Link to tenant',
        location: 'Location',
        locationCoordinates: 'Location Coordinates',
        locations: 'Locations',
        logIn: 'Log In',
        logInLowerSecond: 'Log in',
        logInTo: 'Log in to {appName}',
        logout: 'Logout',
        pastDateTime: 'Please provide a past date and time',
        phoneHintUK: 'UK or Irish phone number. eg 441234567891 or 35312345678',
        phoneHintUnrestricted: 'Phone number',
        phoneHintUS: 'US phone number. eg 15555551234',
        phoneLabelUK: 'Phone Number',
        phoneLabelUS: 'Phone Number',
        phoneNumber: 'Phone number',
        phonePlaceholderUK: 'Enter Phone Number',
        phonePlaceholderUS: 'Enter Phone Number',
        mobileHintUnrestricted: 'Mobile or cell number',
        mobilePhoneHintUK: 'UK or Irish mobile phone number.',
        mobilePhoneHintUS: 'US cellphone number.',
        mobilePhoneLabelUK: 'Mobile Phone Number',
        mobilePhoneLabelUS: 'Cellphone Number',
        mobilePhonePlaceholderUK: 'Enter Mobile Number',
        mobilePhonePlaceholderUS: 'Enter Cell Phone Number',
        month: 'Month',
        myGroup: 'My Group',
        myOrganisation: 'My Organization',
        name: 'Name',
        npi: 'NPI',
        options: 'Options',
        organisation: 'Organization',
        organisationLink: 'Link to individual organization',
        organisationLower: 'organization',
        organisations: 'Organizations',
        organisationsLower: 'organizations',
        password: 'Password',
        recordedBy: 'Recorded by',
        records: 'Records',
        recordTest: 'Record a test',
        region: 'Region',
        reset: 'Reset',
        result: 'Result',
        results: 'Results',
        save: 'Save',
        saveChanges: 'Save changes',
        selectDate: 'Select Date',
        selectTime: 'Select Time',
        signUpNow: 'Sign Up Now',
        status: 'Status',
        streetAddress: 'Street Address',
        streetAddress2: 'Street Address 2',
        subject: 'Test Subject',
        subjectLink: 'Link to test subject',
        submit: 'Submit',
        submitTest: 'Submit Test',
        suspend: 'Suspend',
        suspended: 'Suspended',
        suspendedLower: 'suspended',
        tenant: 'Tenant',
        tenantLink: 'Link to individual tenant',
        tenantLower: 'tenant',
        tenants: 'Tenants',
        tenantsLower: 'tenants',
        test: 'Test',
        testID: 'Test ID',
        testInitial: 'T',
        testLink: 'Link to individual test record',
        testLocation: 'Test Location',
        testLower: 'test',
        testRecord: 'Test Record',
        testRecords: 'Test Records',
        tests: 'Tests',
        testsLower: 'tests',
        time: 'Time',
        to: 'To',
        total: 'Total',
        totalTests: 'Total Tests',
        unknown: 'unknown',
        unknownError: 'An unknown error has occurred',
        user: 'User',
        userLink: 'Link to individual user',
        userLower: 'user',
        users: 'Users',
        usersLower: 'users',
        userType: 'User Type',
        viewIn: 'Ver en',
        year: 'Year',
        zip: 'ZIP',
        zipLabelUK: 'Postcode',
        zipLabelUS: 'ZIP code',
        zipPlaceholderIRL: 'Enter an Irish Postcode',
        zipPlaceholderUK: 'Enter a UK Postcode',
        zipPlaceholderUS: 'Enter a ZIP code',
        zipWarningIRL: 'Please enter a valid Irish Postcode',
        zipWarningUK: 'Please enter a valid UK Postcode',
        zipWarningUS: 'Please enter a valid US Zip Code',
        error: 'error'
    },
    es_MX: {
        active: 'Activo',
        address: 'Dirección',
        allResults: 'Todos los resultados',
        cancel: 'Cancelar',
        checkInbox: '¡Revisa tu correo!',
        checkSpam: 'Es posible que tenga que revisar su carpeta de spam.',
        city: 'Ciudad',
        clear: 'Reiniciar',
        clia: 'CLIA',
        companyLogo: 'Logotipo de Placeholder',
        continue: 'Continuar',
        country: 'País',
        county: 'Condado',
        customId: 'Identificación personalizada 1',
        secondCustomId: 'Identificación personalizada 2',
        dashboard: 'Panel',
        dashboardLower: 'panel',
        date: 'Fecha',
        dateOfBirth: 'Fecha de cumpleaños',
        day: 'Día',
        description: 'Descripción',
        done: 'Hecho',
        email: 'Correo electrónico',
        emailAddress: 'Dirección de correo electrónico',
        from: 'Desde',
        futureDateTime: 'Proporcione una fecha y hora futuras',
        goTO: 'Ir a {page}',
        group: 'Grupo',
        groupLink: 'Enlace al grupo',
        groupLower: 'grupo',
        groups: 'Grupos',
        groupsLower: 'grupos',
        invite: 'invitación',
        invitedBy: 'Invitado por',
        invitedWhen: 'Invitado cuando',
        inviteHint: 'enviado con correo electrónico de invitación',
        inviteLabel: 'Codigo de invitacion',
        invitePlaceholder: 'Ingrese su código de 24 caracteres',
        invites: 'Invitaciones',
        invitesLower: 'invitaciones',
        labName: 'Laboratory Name',
        lastLogin: 'Último acceso',
        linkToOrg: 'Enlace a org',
        linkToTenant: 'Enlace a inquilino',
        location: 'Localización',
        locationCoordinates: 'Coordenadas de ubicación',
        locations: 'Ubicaciones',
        logIn: 'Iniciar sesión',
        logInLowerSecond: 'Iniciar sesión',
        logInTo: 'Iniciar sesión en {appName}',
        logout: 'Cerrar sesión',
        pastDateTime: 'Proporcione una fecha y hora pasadas',
        phoneHintUnrestricted: 'Número de teléfono en formato internacional',
        phoneHintUK:
            'Reino Unido o número de teléfono irlandés en formato internacional. por ejemplo, 441234567891 o 35312345678',
        phoneHintUS: 'Número de teléfono de EE. UU. En formato internacional. p.ej. 15555551234',
        phoneLabelUK: 'Número de teléfono',
        phoneLabelUS: 'Número de teléfono',
        phoneNumber: 'Número de teléfono',
        phonePlaceholderUK: 'Ingresa número telefónico',
        phonePlaceholderUS: 'Ingresa número telefónico',
        mobileHintUnrestricted: 'Número de móvil o celular en formato internacional',
        mobilePhoneHintUK:
            'Número de teléfono móvil del Reino Unido o Irlandés en formato internacional. por ejemplo, 447234567891 o 35382345678',
        mobilePhoneHintUS: 'Número de teléfono celular estadounidense en formato internacional. p.ej. 15555551234',
        mobilePhoneLabelUK: 'Número de teléfono móvil',
        mobilePhoneLabelUS: 'Número Celular',
        mobilePhonePlaceholderUK: 'Ingrese el número de móvil',
        mobilePhonePlaceholderUS: 'Ingrese el número de teléfono celular',
        month: 'Mes',
        myGroup: 'Mi Grupo',
        myOrganisation: 'Mi Organización',
        name: 'Nombre',
        npi: 'NPI',
        options: 'Opciones',
        organisation: 'Organización',
        organisationLink: 'Enlace a la organización individual',
        organisationLower: 'organización',
        organisations: 'Organizaciones',
        organisationsLower: 'Organizaciones',
        password: 'Clave',
        recordedBy: 'Grabado por',
        records: 'Registros',
        recordTest: 'Grabar una prueba',
        reset: 'Reiniciar',
        result: 'Resultado',
        results: 'Resultados',
        save: 'Ahorrar',
        saveChanges: 'Guardar cambios',
        selectDate: 'Seleccione fecha',
        selectTime: 'Seleccionar hora',
        selfRegMobilePhoneHintUK: 'Número de teléfono móvil del Reino Unido o Irlanda en formato internacional',
        selfRegMobilePhoneHintUS: 'Número de teléfono móvil de EE. UU. En formato internacional',
        selfRegMobilePhoneLabelUK: 'Número de teléfono móvil',
        selfRegMobilePhoneLabelUS: 'Número Celular',
        selfRegMobilePhonePlaceholderUS: '11234567890',
        signUpNow: 'Regístrate Ahora',
        status: 'Estado',
        subject: 'Sujeto',
        subjectLink: 'Enlace al sujeto',
        submit: 'Entregar',
        submitTest: 'Enviar prueba',
        suspend: 'Suspender',
        suspended: 'Suspendido',
        suspendedLower: 'suspendido',
        tenant: 'Inquilino',
        tenantLink: 'Enlace a inquilino individual',
        tenantLower: 'Enlace a inquilino individual',
        tenants: 'Inquilinos',
        tenantsLower: 'inquilinos',
        test: 'Prueba',
        testID: 'ID de prueba',
        testInitial: 'P',
        testLink: 'Enlace al registro de prueba individual',
        testLocation: 'Ubicación de la prueba',
        testLower: 'prueba',
        testRecord: 'Pruebas',
        testRecords: 'pruebas',
        tests: 'Pruebas',
        testsLower: 'pruebas',
        time: 'tiempo',
        to: 'Para',
        total: 'total',
        totalTests: 'Pruebas totales',
        unknown: 'desconocido',
        unknownError: 'Un error desconocido a ocurrido',
        user: 'Usuario',
        userLink: 'Enlace al usuario individual',
        userLower: 'usuario',
        users: 'Usuarios',
        usersLower: 'usuarios',
        userType: 'Tipo de usuario',
        viewIn: 'View In',
        year: 'Año',
        zipLabelUK: 'Código postal',
        zipLabelUS: 'Código postal',
        zipPlaceholderIRL: 'Ingrese un código postal irlandés',
        zipPlaceholderUK: 'Ingrese un código postal del Reino Unido o Irlandés',
        zipPlaceholderUS: 'Ingrese un código postal',
        zipWarningIRL: 'Por favor ingrese un código postal irlandés válido',
        zipWarningUK: 'Por favor ingrese un código postal válido del Reino Unido',
        zipWarningUS: 'Por favor ingrese un código postal válido de EE. UU.',
        error: 'error'
    }
}

export default generic
