const crud = {
    en_GB: {
        //e.g. Tenant updated
        resourceUpdated: '{resource} updated',
        resourceCreated: '{resource} created'
    },
    en_US: {
        //e.g. Tenant updated
        resourceUpdated: '{resource} updated',
        resourceCreated: '{resource} created'
    },
    es_MX: {
        //e.g. Tenant updated
        resourceUpdated: '{resource} actualizado',
        resourceCreated: '{resource} creado'
    }
}

export default crud
