<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path id="a" d="M0 0h24v24H0z" />
            <path
                d="M20.291 5.5c.461 0 .861.176 1.2.527.34.351.509.757.509 1.216v9.514c0 .46-.17.865-.509 1.216-.339.351-.739.527-1.2.527H3.71c-.461 0-.861-.176-1.2-.527-.34-.351-.509-.757-.509-1.216V7.243c0-.46.17-.865.509-1.216.339-.351.739-.527 1.2-.527H20.29zM12 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-5 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm10 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"
                id="b"
            />
        </defs>
        <g fill="none" fill-rule="evenodd">
            <mask id="c" fill="#fff">
                <use xlink:href="#b" />
            </mask>
            <use fill="#D8D8D8" xlink:href="#b" />
            <g mask="url(#c)" fill="currentColor" fill-rule="nonzero">
                <path d="M-9-9h42v42H-9z" />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'InviteIcon'
}
</script>

<style scoped></style>
