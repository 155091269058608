import { NavigationGuardNext, Route } from 'vue-router'
import Tenant from '@/services/tenant'
import store from '@/store'
const tenant = Tenant.getInstance()

const misc: any[] = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/modules/_global/views/auth/Login.vue'),
        props: true
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('@/modules/_global/views/errors/Error.vue'),
        props: true
    },
    {
        path: '/account-verified',
        name: 'accountVerified',
        component: () => import('@/modules/_global/views/registration/CustomLandingPage.vue'),
        props: true,
        beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
            if (!to.params?.shouldAllowAccessToLandingPage || store.getters['authStore/isUserLoggedIn']) {
                next('/')
                return
            }

            next()
        }
    },
    {
        path: '/logged-out',
        name: 'loggedOut',
        component: () => import('@/modules/_global/views/auth/LoggedOut.vue')
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/'
    }
]

export default misc
