<template>
    <div>
        <div
            class="result-icon-wrap d-flex flex-column align-center justify-center"
            :class="[result, { 'is-card-grid-icon': isCardGridIcon }]"
        >
            <div class="result-icon-fill d-flex flex-column align-center justify-center">
                <v-icon color="#fff" :small="isCardGridIcon">{{ getResultsIcon(result) }}</v-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ResultIcon',
    props: {
        result: {
            type: String,
            required: true
        },
        isCardGridIcon: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getResultsIcon(result) {
            switch (result) {
                case 'positive':
                    return 'mdi-plus'
                case 'negative':
                    return 'mdi-minus'
                case 'inconclusive':
                    return 'mdi-exclamation'
                default:
                    return
            }
        }
    }
}
</script>

<style scoped lang="scss">
.result-icon-wrap {
    @include circle($size: 2.5rem);

    .result-icon-fill {
        @include circle($size: 1.625rem);
    }

    &.positive {
        .result-icon-fill {
            background-color: var(--v-positive-darken4);
        }
    }

    &.negative {
        .result-icon-fill {
            background-color: var(--v-negative-darken4);
        }
    }

    &.inconclusive {
        .result-icon-fill {
            background-color: var(--v-inconclusive-darken4);
        }
    }

    &.is-card-grid-icon {
        @include circle($size: 0.75rem);

        .result-icon-fill {
            @include circle($size: 0.75rem);

            ::v-deep.v-icon {
                font-size: 8px !important;
            }
        }

        @media screen and (min-width: 768px) {
            @include circle($size: 1rem);

            .result-icon-fill {
                @include circle($size: 1rem);

                ::v-deep.v-icon {
                    font-size: 16px !important;
                }
            }
        }
    }
}
</style>
