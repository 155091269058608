import { get } from 'lodash'
import { handleError } from '@/helpers/error'
import { Verification } from '@/interfaces/auth'
import http from '@/services/api/http'
import Tenant from '@/services/tenant'
const tenant = Tenant.getInstance()

export const handleVerification = async (verification: Verification) => {
    const { to, next, callback, notification } = verification

    if (!get(to.query, 'id')) {
        next('/login')
        return
    }

    try {
        const response = await http.get(to.fullPath.substring(1))

        if (response?.status === 200) {
            if (callback) {
                callback(next, response)
                return
            }

            const shouldAllowAccessToLandingPage =
                tenant.getFeatureFlags().includes('PREVENT_SELF_REGISTRATION_WEB_LOGIN') &&
                to.path === '/verify-new-self-registration'

            if (shouldAllowAccessToLandingPage) {
                next({ name: 'accountVerified', params: { shouldAllowAccessToLandingPage } })
                return
            }

            if (notification) {
                next({ name: 'login', params: { notification }, replace: true })
                return
            }

            next('/login')
        }
    } catch (error) {
        handleError(error as Error)
    }
}
