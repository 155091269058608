const store = {
    en_GB: {
        webAppNoPermission:
            'Your account type does not have permission to access the service via the web. If you believe this is incorrect, please contact your administrator.'
    },
    en_US: {
        webAppNoPermission:
            'Your account type does not have permission to access the service via the web. If you believe this is incorrect, please contact your administrator.'
    },
    es_MX: {
        webAppNoPermission:
            'Su tipo de cuenta no tiene permiso para acceder al servicio a través de la web. Si cree que esto es incorrecto, póngase en contacto con su administrador.'
    }
}

export default store
