export function destructure(vueInstance: any) {
    const { proxy } = vueInstance() as any

    const appName = proxy.$appName
    const appVersion = proxy.$appVersion
    const i18n = proxy.$i18n
    const route = proxy.$route
    const router = proxy.$router
    const store = proxy.$store
    const vuetify = proxy.$vuetify
    const appNameTm = `${appName}\u2122`

    return {
        appName,
        appVersion,
        i18n,
        route,
        router,
        store,
        vuetify,
        appNameTm
    }
}
