import { Country } from '@/enums/country'

export default {
    name: 'Bond Tenant',
    description: 'Test tenant for demo and test purposes',
    assayId: 'b82f54d4-deb9-4ab3-a887-bed170a8d9b5',
    preferences: {
        productName: 'Transform Platform',
        availableLanguages: ['en'],
        idPrefix: 'BRID',
        defaultLanguage: 'en_GB',
        features: [],
        theme: {
            web: {
                primary: {
                    base: '#1d636d',
                    lighten1: '#a0a9d2',
                    darken1: '#3a436c'
                },
                secondary: {
                    base: '#1497a8',
                    lighten1: '#858db0',
                    darken1: '#1f274a'
                },
                hover: '#dfe2f0',
                accent: {
                    base: '#ff7500',
                    lighten1: '#c3d266',
                    darken1: '#5d6c00'
                },
                anchor: '#ff7501',
                grey: '#748283',
                textLink: '#FF7501'
            }
        },
        appLinks: {
            local: {
                ios: 'https://testflight.apple.com?tenant=bond',
                android: 'https://install.appcenter.ms/orgs/Bondhealth/apps/bond-Android-Dev?tenant=bond'
            },
            'local-fe': {
                ios: 'https://testflight.apple.com?tenant=bond',
                android: 'https://install.appcenter.ms/orgs/Bondhealth/apps/bond-Android-Dev?tenant=bond'
            },
            develop: {
                ios: 'https://testflight.apple.com?tenant=bond',
                android: 'https://install.appcenter.ms/orgs/Bondhealth/apps/bond-Android-Dev?tenant=bond'
            },
            staging: {
                ios: 'https://testflight.apple.com?tenant=bond',
                android: 'https://install.appcenter.ms/orgs/Bondhealth/apps/bond-Android-Dev?tenant=bond'
            },
            qa: {
                ios: 'https://testflight.apple.com?tenant=bond',
                android: 'https://install.appcenter.ms/orgs/Bondhealth/apps/bond-Android-Dev?tenant=bond'
            },
            production: {
                ios: 'https://bondhealth.co.uk/',
                android: 'https://bondhealth.co.uk/'
            }
        },
        countries: [Country.GB, Country.IE],
        phoneRegex: null,
        postcodeRegex: null,
        dateFormat: {
            web: {
                short: 'dd-MM-y',
                long: 'DDDD',
                date_time: 'F'
            },
            mobile: {
                short: 'dd-MM-yyyy',
                long: 'EEE, dd MMM, yyyy',
                date_time: 'dd-MM-yyyy h:mm a'
            }
        }
    },
    slug: 'bond',
    status: 'Active',
    statusDetails: {
        flag: 'active',
        displayName: 'Active'
    }
}
