import { DateTimeFormats } from 'vue-i18n'

const dateTimeFormats: DateTimeFormats = {
    'en-GB': {
        short: { year: 'numeric', month: 'long', day: 'numeric' },
        long: {
            weekday: 'short',
            month: 'long',
            year: 'numeric',
            day: 'numeric'
        },
        monthYear: {
            month: 'long',
            year: 'numeric'
        }
    },
    'en-US': {
        short: { year: 'numeric', month: 'long', day: 'numeric' },
        long: {
            weekday: 'short',
            month: 'long',
            year: 'numeric',
            day: 'numeric'
        },
        monthYear: {
            month: 'long',
            year: 'numeric'
        }
    },
    'es-MX': {
        short: { year: 'numeric', month: 'long', day: 'numeric' },
        long: {
            weekday: 'short',
            month: 'long',
            year: 'numeric',
            day: 'numeric'
        },
        monthYear: {
            month: 'long',
            year: 'numeric'
        }
    }
}
export default dateTimeFormats
