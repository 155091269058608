const selfRegistration = {
    en_GB: {
        signUp: 'Sign up',
        signUpMessage: 'New to {serviceName}? Sign up below and create a new user account.',
        createAccount: 'Create Account',
        inbox: 'An email has been sent to {email}, with further instructions to complete registration.'
    },
    en_US: {
        signUp: 'Sign up',
        signUpMessage: 'New to {serviceName}? Sign up below and create a new user account.',
        createAccount: 'Create Account',
        inbox: 'An email has been sent to {email}, with further instructions to complete registration.'
    },
    es_MX: {
        signUp: 'Sign up',
        signUpMessage: 'Eres nuevo en {serviceName}? Regístrese a continuación y cree una nueva cuenta de usuario.',
        createAccount: 'Create account',
        inbox: 'Se ha enviado un correo electrónico a {email}, con más instrucciones para completar el registro.'
    }
}

export default selfRegistration
