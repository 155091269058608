const groups = {
    en_GB: {
        btnNewGroup: 'Create New Group',
        createNewGroup: 'Create a new group',
        editGroup: 'Edit this group',
        editOwnGroup: 'Edit my group',
        enterDesc: 'Enter group description',
        enterLocation: 'Enter group location',
        enterName: 'Enter group name',
        enterLabName: 'Enter lab name',
        enterClia: 'Example: 12D345678',
        labDetailsTitle: 'Laboratory (Optional)',
        addLabDetails: 'Add Laboratory Details',
        removeLabDetails: 'Remove Laboratory Details'
    },
    en_US: {
        btnNewGroup: 'Create New Group',
        createNewGroup: 'Create a new group',
        editGroup: 'Edit this group',
        editOwnGroup: 'Edit my group',
        enterDesc: 'Enter group description',
        enterLocation: 'Enter group location',
        enterName: 'Enter group name',
        enterLabName: 'Enter lab name',
        enterClia: 'Example: 12D345678',
        labDetailsTitle: 'Laboratory (Optional)',
        addLabDetails: 'Add Laboratory Details',
        removeLabDetails: 'Remove Laboratory Details'
    },
    es_MX: {
        btnNewGroup: 'Crear nuevo grupo',
        createNewGroup: 'Crear nuevo grupo',
        editGroup: 'Editar este grupo',
        editOwnGroup: 'Editar mi grupo',
        enterDesc: 'Ingrese la descripción del grupo',
        enterLocation: 'Ingrese la descripción del grupo',
        enterName: 'Ingrese la descripción del grupo',
        enterLabName: 'Ingrese el nombre del laboratorio',
        enterClia: 'Ejemplo: 12D345678',
        labDetailsTitle: 'Laboratorio (Opcional)',
        addLabDetails: 'Agregar detalles de laboratorio',
        removeLabDetails: 'Eliminar detalles de laboratorio'
    }
}

export default groups
