/**
 * Route Guards
 * Will return true if guard requirements are met else will trigger
 * any side effects such as notifications and return false which will
 * break the conditional logic in the route beforeEnter
 */

import { UserTypes } from '@/enums/users'
import i18n from '@/plugins/i18n'

/**
 * Checks if the required permission for this route exists in logged in
 * users permissons and if not displays 404
 * @param required eg. create-tests
 * @param store
 */
export const permissionsCheck = ({ required, store }): boolean => {
    if (store.getters['authStore/getPermissions'].includes(required)) return true
    else {
        store.commit('setNotificationEvent', `${i18n.t('http.404')}`)
        return false
    }
}

/**
 * Checks if the required permission for this route exists in logged in
 * users permissons and if not displays 404
 * @param required eg. create-tests
 * @param store
 */
export const userTypeCheck = ({ acceptedUserTypes, store }): boolean => {
    const userType = store.getters['authStore/getUserType']
    if (acceptedUserTypes.includes(userType)) return true
    else {
        store.commit('setNotificationEvent', `${i18n.t('http.403')}`)
        return false
    }
}

/**
 * Checks flags and user type and returns true if PREVENT_CLINICIAN_TESTING flag
 * is found and user is type clinician
 */
export const preventClinicianTesting = (tf: string[], prf: string[], store: any) => {
    const prevented = tf.includes('PREVENT_CLINICIAN_TESTING') && prf.includes('PREVENT_CLINICIAN_TESTING')
    const isClinician = store.getters['authStore/getUserType'] === UserTypes.Clinical
    return prevented && isClinician
}

/**
 * Checks if there are any feature flags present that restrict this user
 * from visiting this route & if so displays 404
 * @param preventedRouteFlags eg. PREVENT_CLINICIAN_TESTING
 * @param tenantFlags tenant feature flags
 * @param store
 */
export const featureFlagCheck = ({
    preventedRouteFlags,
    tenantFlags,
    store
}: {
    preventedRouteFlags: string[]
    tenantFlags: string[]
    store: any
}): boolean => {
    const preventedRoute = tenantFlags.some(item => preventedRouteFlags.includes(item))
    if (preventedRoute) {
        if (preventClinicianTesting(tenantFlags, preventedRouteFlags, store)) {
            store.commit('setNotificationEvent', `${i18n.t('http.403')}`)
            return false
        }
        return true
    }
    return true
}

/**
 * Checks if current logged in user appears in acceptedUsers array and if not redirects to dashboard and displays 401
 * @param userTypes
 * @param next
 * @param store
 */
export const userTypeGuard = (acceptedUserTypes: UserTypes[], next: (...args: any) => void, store) => {
    const userType = store.getters['authStore/getUserType']
    if (!acceptedUserTypes.includes(userType)) {
        next({ path: '/' })
        store.commit('setNotificationEvent', `${i18n.t('http.403')}`)
    } else next()
}
