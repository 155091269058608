const layouts = {
    en_GB: {
        appVersion: 'v{versionNum}',
        bondCopyrightYear: '{copySymbol} {year} Bond Digital Health.',
        transformByBond: '{copySymbol} {year} Transform{regSymbol} by Bond Digital Health.',
        transformLogo: 'Transform Logo',
        myTransform: 'My Transform®'
    },
    en_US: {
        appVersion: 'v{versionNum}',
        bondCopyrightYear: '{copySymbol} {year} Bond Digital Health.',
        transformByBond: '{copySymbol} {year} Transform{regSymbol} by Bond Digital Health.',
        transformLogo: 'Transform Logo',
        myTransform: 'My Transform®'
    },
    es_MX: {
        appVersion: 'v{versionNum}',
        bondCopyrightYear: '{copySymbol} {year} Bond Digital Health.',
        transformByBond: '{copySymbol} {year} Transform{regSymbol} por Bond Digital Health.',
        transformLogo: 'Transform Logo',
        myTransform: 'Mi Transform®'
    }
}

export default layouts
