import { replace } from 'lodash'
import Tenant from '@/services/tenant'
import i18n from '@/plugins/i18n'

/**
 * Takes the tenant as an argument and returns the preferred date format with
 * hyphens replaced with forward slashes so that it matches the designs
 * @example getDateInputFormat(tenant) //=> 'dd/LL/yyyy'
 * @param tenant
 * @returns
 */
export const getDateInputFormat = (tenant: Tenant) => {
    return replace(tenant.getDateFormat('short') || 'dd-LL-yyyy', /-/g, '/')
}

/**
 * Takes the tenant as an argument and returns the correct input validation
 * message based on the tenants preferred date format
 * eg. Please provide date in DD/MM/YYYY format
 * @example getDateInputFormatMessage(tenant) //=> 'Please provide date in DD/MM/YYYY format'
 * @param tenant
 * @returns
 */
export const getDateInputFormatMessage = (tenant: Tenant) => {
    switch (tenant.getDateFormat('short')) {
        case 'MM-dd-y':
            return `${i18n.t('generic.month')}/${i18n.t('generic.day')}/${i18n.t('generic.year')}`
        case 'y/MM/dd':
            return `${i18n.t('generic.year')}/${i18n.t('generic.month')}/${i18n.t('generic.day')}`
        default:
            return `${i18n.t('generic.day')}/${i18n.t('generic.month')}/${i18n.t('generic.year')}`
    }
}
