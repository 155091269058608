const users = {
    en_GB: {
        confirmationEmail: 'A confirmation email will be sent to this email address.',
        editMyDetails: 'Edit my details',
        editUser: 'Edit this user',
        enterEmail: 'Enter email address',
        fullName: 'Enter name and surname',
        newAddress: 'your new address',
        newUser: 'Add a new user',
        newUserUpper: 'Add New User',
        unverified: 'unverified',
        unverifiedEmail: 'Unverified email address.',
        verificationEmail: 'A verification email will be sent to the new address',
        verificationEmailNew: 'A verification email has been sent to',
        verified: 'verified',
        verifiedEmail: 'Verified email address.',
        changingEmail: 'Changing your Email',
        changingEmailLogout: 'If you edit your current email address, you will be logged out after saving the change.',
        changingEmailVerify:
            'You will no longer be able to login with your current email address, and must verify your new address in order to login.',
        settings: 'My settings',
        editSettings: 'Edit my settings',
        profile: 'Profile',
        accountDeletionHeading: 'Delete your account?',
        accountDeletionSubmit: 'Yes, Delete my account',
        accountDeletionNotification: 'User Account Deleted',
        accountDeletionMessage:
            'When your account is deleted, you can no longer login to {productName}. We may retain anonymised data. All your personal information is permanently deleted. This action cannot be undone. To proceed, please enter your current password and confirm deletion.'
    },
    en_US: {
        confirmationEmail: 'A confirmation email will be sent to this email address.',
        editMyDetails: 'Edit my details',
        editUser: 'Edit this user',
        enterEmail: 'Enter email address',
        fullName: 'Enter name and surname',
        newAddress: 'your new address',
        newUser: 'Add a new user',
        newUserUpper: 'Add New User',
        unverified: 'unverified',
        unverifiedEmail: 'Unverified email address.',
        verificationEmail: 'A verification email will be sent to the new address',
        verificationEmailNew: 'A verification email has been sent to',
        verified: 'verified',
        verifiedEmail: 'Verified email address.',
        changingEmail: 'Changing your Email',
        changingEmailLogout: 'If you edit your current email address, you will be logged out after saving the change.',
        changingEmailVerify:
            'You will no longer be able to login with your current email address, and must verify your new address in order to login.',
        settings: 'My settings',
        editSettings: 'Edit my settings',
        profile: 'Profile',
        accountDeletionHeading: 'Delete your account?',
        accountDeletionSubmit: 'Yes, Delete my account',
        accountDeletionNotification: 'User Account Deleted',
        accountDeletionMessage:
            'When your account is deleted, you can no longer login to {productName}. We may retain anonymised data. All your personal information is permanently deleted. This action cannot be undone. To proceed, please enter your current password and confirm deletion.'
    },
    es_MX: {
        confirmationEmail: 'Se enviará un correo electrónico de confirmación a esta dirección de correo electrónico.',
        editMyDetails: 'Editar mis detalles',
        editUser: 'Editar este usuario',
        enterEmail: 'Introducir la dirección de correo electrónico',
        fullName: 'Ingrese el nombre y el apellido',
        newAddress: 'Tu nueva dirección',
        newUser: 'Añadir un nuevo usuario',
        newUserUpper: 'Añadir nuevo usuario',
        unverified: 'inconfirmado',
        unverifiedEmail: 'Dirección de correo electrónico no fija.',
        verificationEmail: 'Se enviará un correo electrónico de verificación a la nueva dirección.',
        verificationEmailNew: 'Un email de verificación ha sido enviado a',
        verified: 'verificado',
        verifiedEmail: 'Dirección de correo electrónico verificada.',
        changingEmail: 'Cambiar su correo electrónico',
        changingEmailLogout:
            'Si edita su dirección de correo electrónico actual, se cerrará su sesión después de guardar el cambio.',
        changingEmailVerify:
            'Ya no podrá iniciar sesión con su dirección de correo electrónico actual y deberá verificar su nueva dirección para iniciar sesión.',
        settings: 'Mi configuración',
        editSettings: 'Editar mi configuración',
        profile: 'Perfil',
        accountDeletionHeading: 'Eliminar tu cuenta?',
        accountDeletionSubmit: 'Sí, Eliminar mi cuenta',
        accountDeletionNotification: 'Cuenta de usuario eliminada',
        accountDeletionMessage:
            'Cuando se elimina su cuenta, ya no puede iniciar sesión en {productName}. Podemos retener datos anonimizados. Toda su información personal se elimina de forma permanente. Esta acción no se puede deshacer. Para continuar, ingrese su contraseña actual y confirme la eliminación.'
    }
}

export default users
