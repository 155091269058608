const filtering = {
    en_GB: {
        allTime: 'All Time',
        customRange: 'Custom Range',
        lastSevenDays: 'Last 7 Days',
        lastMonth: 'Last Month',
        reviewPeriod: 'Review Period',
        thisMonth: 'This Month',
        today: 'Today',
        yesterday: 'Yesterday'
    },
    en_US: {
        allTime: 'All Time',
        customRange: 'Custom Range',
        lastSevenDays: 'Last 7 Days',
        lastMonth: 'Last Month',
        reviewPeriod: 'Review Period',
        thisMonth: 'This Month',
        today: 'Today',
        yesterday: 'Yesterday'
    },
    es_MX: {
        allTime: 'Todo el tiempo',
        customRange: 'Rango personalizado',
        lastSevenDays: 'Los últimos 7 días',
        lastMonth: 'El mes pasado',
        reviewPeriod: 'Período de revisión',
        thisMonth: 'Este mes',
        today: 'Hoy dia',
        yesterday: 'El dia de ayer'
    }
}

export default filtering
