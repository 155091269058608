import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=1970dd22&scoped=true&"
import script from "./Navigation.vue?vue&type=script&lang=ts&"
export * from "./Navigation.vue?vue&type=script&lang=ts&"
import style0 from "./Navigation.vue?vue&type=style&index=0&id=1970dd22&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1970dd22",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VAppBarNavIcon,VBtn,VIcon,VNavigationDrawer,VOverlay,VSpacer})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
