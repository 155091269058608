


























import { defineComponent } from '@vue/composition-api'
import { v4 } from 'uuid'

export default defineComponent({
    name: 'TestIcon',
    setup() {
        const uuid = v4()
        return {
            uuid
        }
    }
})
