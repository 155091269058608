const permission = {
    permission: 'view-orgs'
}

const organisations = {
    path: '/organisations',
    name: 'organisations',
    component: () => import('@/modules/organisations/views/List.vue'),
    meta: { ...permission, pageTitle: 'generic.organisations' },
    children: [
        {
            path: ':id',
            name: 'organisation',
            component: () => import('@/modules/organisations/views/View.vue'),
            meta: { ...permission, pageTitle: 'generic.organisation' }
        }
    ]
}

export default organisations
