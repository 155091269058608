import { TenantPreferences } from '@/interfaces/tenants'
import http from '@/services/api/http'
import i18n from '@/plugins/i18n'
import bond from '@/themes/bond'
import { get } from 'lodash'

class Tenant {
    private static instance: Tenant
    public readonly id: string = ''
    public readonly currentBaseUrl: string = ''
    private currentTenant: TenantPreferences

    constructor() {
        const hostname = window.location?.hostname
        if (hostname) this.currentBaseUrl = hostname.replace('.local-fe.', '.local.')
    }

    /**
     * If no argument is provided then this will return the current singleton
     * instance of the tenant class. If no instance exists it will create a
     * new one. If args are provided it will return a new instance of the class
     * using these args
     * @param args
     * @returns
     */
    public static getInstance(): Tenant {
        if (!Tenant.instance) Tenant.instance = new Tenant()
        return Tenant.instance
    }

    /**
     * Returns the theme settings from the tenant preferences
     * @returns
     */
    public getSlug() {
        return this.currentTenant.slug
    }

    /**
     * Returns the theme settings from the tenant preferences
     * @returns
     */
    public getTheme() {
        return this.currentTenant.preferences?.theme?.web || bond.preferences.theme.web
    }

    /**
     * Returns the product name as defined in the tenant preferences
     * @returns
     */
    public getProductName() {
        return this.currentTenant.preferences?.productName || 'Transform'
    }

    /**
     * Returns the tenants preferred date format as defined in the tenant
     * preferences
     * @returns
     */
    public getDateFormat(type: 'short' | 'long') {
        return this.currentTenant.preferences?.dateFormat.web[type] || bond.preferences.dateFormat[type]
    }

    /**
     * Returns an array of countries that the tenant supports as defined in the
     * tenant preferences
     * @returns
     */
    public getCountries() {
        return this.currentTenant.preferences?.countries || bond.preferences.countries
    }

    /**
     * Returns the tenants preferred language as defined in the tenant
     * preferences
     * @returns
     */
    public getDefaultLanguage() {
        const defaultLanguage = this.currentTenant.preferences?.defaultLanguage
        const valid = /[a-z]{2}(_|-)[A-Z]{2}/.test(defaultLanguage)
        return valid ? defaultLanguage.replace('-', '_') : bond.preferences.defaultLanguage
    }

    /**
     * Returns the native mobile app store links for the tenent
     * and each of the environments
     * @returns
     */
    public getAppLinks() {
        return this.currentTenant.preferences?.appLinks
    }

    /**
     * Returns a list of human readable country options for this tenant.
     * GBR = The United Kingdom of Great Britain and Northern Ireland, etc
     * @returns
     */
    public getCountrySelectOptions() {
        return this.getCountries().map((countryCode: string) => {
            return { name: i18n.t(`countries.${countryCode}`), value: countryCode }
        })
    }

    /**
     * Returns a list of the feature flags for this tenant
     * @returns
     */
    public getFeatureFlags() {
        return this.currentTenant.preferences?.features || []
    }

    /**
     * Returns the assets for the tenant unless they missing in which
     * case the default transform ones are returned
     * @returns
     */
    public getAssets() {
        try {
            return {
                marque: require(`@/assets/themes/${this.getSlug()}/marque.svg`),
                logo: require(`@/assets/themes/${this.getSlug()}/logo.svg`),
                overlay: require(`@/assets/themes/${this.getSlug()}/overlay.jpg`)
            }
        } catch (error) {
            return {
                marque: require(`@/assets/themes/transform/marque.svg`),
                logo: require(`@/assets/themes/transform/logo.svg`),
                overlay: require(`@/assets/themes/transform/overlay.jpg`)
            }
        }
    }

    /**
     * Returns the tenants subject preferences or null if none are specified
     * @returns
     */
    public getSubjectPreferences() {
        return this.currentTenant.preferences.subjects
    }

    /**
     * Initialises the tenant class
     * @returns
     */
    public async init() {
        const sitePreferences = localStorage.getItem('site-preferences')
        const lastCached = localStorage.getItem('cache-updated')
        const now = new Date().getHours() + ''
        if (sitePreferences && lastCached === now) this.currentTenant = JSON.parse(sitePreferences)
        else {
            const response = await http.get('/get-site-preferences')
            this.currentTenant = get(response, 'data.data')
            localStorage.setItem('site-preferences', JSON.stringify(this.currentTenant))
            localStorage.setItem('cache-updated', now)
        }
        return this.currentTenant
    }
}

export default Tenant
