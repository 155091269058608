const testRecords = {
    en_GB: {
        date: 'Date',
        id: 'id',
        testId: 'Test ID',
        subject: 'Test Subject',
        time: 'Time',
        viewTestDetails: 'View test details',
        orgGroupTestAssoc:
            'First select an Organisation, then please select the Group you wish to associate with this test record.'
    },
    en_US: {
        date: 'Date',
        id: 'id',
        testId: 'Test ID',
        subject: 'Test Subject',
        time: 'Time',
        viewTestDetails: 'View test details',
        orgGroupTestAssoc:
            'First select an Organisation, then please select the Group you wish to associate with this test record.'
    },
    es_MX: {
        date: 'Fecha',
        id: 'id',
        testId: 'ID de prueba',
        subject: 'Sujeto',
        time: 'Tiempo',
        viewTestDetails: 'Ver detalles de la prueba',
        orgGroupTestAssoc:
            'Primero seleccione una organización, luego seleccione el grupo que desea asociar con este registro de prueba.'
    }
}

export default testRecords
