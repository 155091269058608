const validation = {
    en_GB: {
        phoneNumber: {
            label: {
                mobile: 'Mobile Phone Number',
                fixed: 'Landline Phone Number',
                default: 'Phone Number'
            },
            placeholder: {
                mobile: 'Mobile Number',
                fixed: 'Landline Number',
                default: 'Phone Number'
            },
            hint: {
                mobile: 'Select country code and enter mobile phone number',
                fixed: 'Select country code and enter landline phone number',
                default: 'Select country code and enter phone number'
            },
            error: {
                code: 'Please select a country code',
                mobile: {
                    invalid: 'Please enter a valid mobile phone number'
                },
                fixed: {
                    invalid: 'Please enter a valid landline phone number'
                },
                default: {
                    invalid: 'Please enter a valid phone number'
                }
            }
        }
    },
    en_US: {
        phoneNumber: {
            label: {
                mobile: 'Cellphone Number',
                fixed: 'Landline Phone Number',
                default: 'Phone Number'
            },
            placeholder: {
                mobile: 'Cellphone Number',
                fixed: 'Landline Number',
                default: 'Phone Number'
            },
            hint: {
                mobile: 'Select country code and enter cellphone number',
                fixed: 'Select country code and enter phone number',
                default: 'Select country code and enter phone number'
            },
            error: {
                code: 'Please select a country code',
                mobile: {
                    invalid: 'Please enter a valid cellphone number'
                },
                fixed: {
                    invalid: 'Please enter a valid landline phone number'
                },
                default: {
                    invalid: 'Please enter a valid phone number'
                }
            }
        }
    },
    es_MX: {
        phoneNumber: {
            label: {
                mobile: 'Número Celular',
                fixed: 'Número de teléfono fijo',
                default: 'Número de teléfono'
            },
            placeholder: {
                mobile: 'Número Celular',
                fixed: 'Número de teléfono fijo',
                default: 'Número de teléfono'
            },
            hint: {
                mobile: 'Seleccione el código de país e ingrese el número de teléfono móvil',
                fixed: 'Seleccione el código de país e ingrese el número de teléfono',
                default: 'Seleccione el código de país e ingrese el número de teléfono'
            },
            error: {
                code: 'Seleccione un código de país',
                mobile: {
                    invalid: 'Proporcione un número de teléfono móvil válido'
                },
                fixed: {
                    invalid: 'Proporcione un número de teléfono fijo válido'
                },
                default: {
                    invalid: 'Por favor provea un número de teléfono válido'
                }
            }
        }
    }
}

export default validation
