const modal = {
    mutations: {
        setIsModalActionCompletedEvent(state: any, isModalActionCompletedEvent = false) {
            state.isModalActionCompletedEvent = isModalActionCompletedEvent
        }
    },
    getters: {
        isModalActionCompletedEvent: state => state.isModalActionCompletedEvent
    }
}

export default modal
