const misc = {
    en_GB: {
        appPrivacy: 'App Privacy policy',
        appPrivacyLabel: 'Link to app privacy policy page',
        closeFullscreen: 'Exit Fullscreen',
        cookies: 'Cookies',
        cookiesLabel: 'Link to cookies page',
        eula: 'EULA',
        eulaLabel: 'Link to EULA page',
        hide: 'Hide',
        hideDescription: 'Hide description',
        member: 'Member since',
        memberSince: 'Member since {date}',
        noDescription: 'No description available.',
        noImage: 'No image',
        noImageDesc: 'Test device image unavailable',
        noLocation: 'No location available.',
        noMap: 'No map',
        noMapDesc: 'Coordinates unavailable',
        openFullscreen: 'View Fullscreen',
        selected: 'selected',
        show: 'Show',
        showDescription: 'Show description',
        showLess: 'Show less',
        showMore: 'Show more',
        terms: 'Terms of use',
        termsLabel: 'Link to terms of use',
        testDevice: 'Test Device',
        webPrivacy: 'Web Privacy policy',
        webPrivacyLabel: 'Link to web privacy policy page',
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out'
    },
    en_US: {
        appPrivacy: 'App Privacy policy',
        appPrivacyLabel: 'Link to app privacy policy page',
        closeFullscreen: 'Exit Fullscreen',
        cookies: 'Cookies',
        cookiesLabel: 'Link to cookies page',
        eula: 'EULA',
        eulaLabel: 'Link to EULA page',
        hide: 'Hide',
        hideDescription: 'Hide description',
        member: 'Member since',
        memberSince: 'Member since {date}',
        noDescription: 'No description available.',
        noImage: 'No image',
        noImageDesc: 'Test device image unavailable',
        noLocation: 'No location available.',
        noMap: 'No map',
        noMapDesc: 'Coordinates unavailable',
        openFullscreen: 'View Fullscreen',
        selected: 'selected',
        show: 'Show',
        showDescription: 'Show description',
        showLess: 'Show less',
        showMore: 'Show more',
        terms: 'Terms of use',
        termsLabel: 'Link to terms of use',
        testDevice: 'Test Device',
        webPrivacy: 'Web Privacy policy',
        webPrivacyLabel: 'Link to web privacy policy page',
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out'
    },
    es_MX: {
        appPrivacy: 'Política de privacidad de la aplicación',
        appPrivacyLabel: 'Enlace a la página Política de privacidad de la aplicación',
        closeFullscreen: 'Cerrar pantalla completa',
        cookies: 'Cookies',
        cookiesLabel: 'Enlace a la página de cookies',
        eula: 'EULA',
        eulaLabel: 'Enlace a la página de EULA',
        hide: 'Ocultar',
        hideDescription: 'Ocultar descripción',
        member: 'Miembro desde',
        memberSince: 'Miembro desde {date}',
        noDescription: 'No hay descripción disponible.',
        noImage: 'Sin imágen',
        noImageDesc: 'No hay imagen Descripción',
        noLocation: 'No hay ubicación disponible.',
        noMap: 'No hay mapa',
        noMapDesc: 'No hay mapa Descripción',
        openFullscreen: 'Abrir pantalla completa',
        selected: 'seleccionado',
        show: 'Mostrar',
        showDescription: 'Mostrar descripcion',
        showLess: 'Muestra menos',
        showMore: 'Mostrar más',
        terms: 'Términos de Uso',
        termsLabel: 'Enlace a los Términos de uso',
        testDevice: 'Dispositivo de prueba',
        webPrivacy: 'Política de privacidad web',
        webPrivacyLabel: 'Enlace a la página Política de privacidad web',
        zoomIn: 'Enlace a la página Política de privacidad web',
        zoomOut: 'Disminuir el zoom'
    }
}

export default misc
