<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="18"
        height="18"
        viewBox="0 0 18 18"
    >
        <defs>
            <path
                id="prefix__a_dashboard"
                d="M9.984 0H18v6H9.984V0zm0 18V8.016H18V18H9.984zM0 18v-6h8.016v6H0zm0-8.016V0h8.016v9.984H0z"
            />
        </defs>
        <g fill="none" fill-rule="evenodd">
            <mask id="prefix__b_dashboard" fill="#fff">
                <use xlink:href="#prefix__a_dashboard" />
            </mask>
            <use fill="#BDC8D2" xlink:href="#prefix__a_dashboard" />
            <g fill="currentColor" fill-rule="nonzero" mask="url(#prefix__b_dashboard)">
                <path d="M0 0L42 0 42 42 0 42z" transform="translate(-12 -12)" />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'DashboardIcon'
}
</script>

<style scoped></style>
