<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="9 9 24 24"
    >
        <defs>
            <path id="0n04rejgqc" d="M0 0H24V24H0z" />
            <path
                id="ncq0lbubnd"
                d="M23.1 6c.497 0 .9.403.9.9v14.2c0 .497-.403.9-.9.9H13V6h10.1zM11 2v16H.9c-.497 0-.9-.403-.9-.9V2.9c0-.497.403-.9.9-.9H11zm7.792 8.5h-.584c-.241 0-.437.196-.437.438v.583h-2.334c-.241 0-.437.196-.437.437v.584c0 .241.196.437.438.437h4.16c-.229.522-.603 1.056-1.095 1.575-.24-.251-.452-.507-.633-.763-.133-.187-.386-.243-.583-.125l-.503.3c-.217.13-.28.416-.136.624.221.318.478.632.765.937-.296.227-.614.447-.951.655-.202.125-.27.385-.152.59l.29.507c.122.215.398.286.608.156.465-.285.896-.59 1.293-.907.398.32.83.623 1.29.907.21.13.487.059.61-.156l.29-.507c.116-.205.05-.465-.151-.59-.328-.202-.645-.421-.95-.656.766-.818 1.303-1.688 1.557-2.547h.416c.241 0 .437-.196.437-.437v-.584c0-.241-.196-.437-.438-.437H19.23v-.583c0-.242-.196-.438-.437-.438zM6.069 6.621H4.93l-.075.007c-.119.022-.222.093-.284.196l-.038.082-2.011 5.917-.018.075c-.033.22.114.429.331.472l.084.009h.799l.078-.008c.128-.024.238-.107.297-.223l.028-.074.32-1.113h2.107l.327 1.116.03.073c.058.115.168.197.295.221l.078.008h.8l.077-.007c.218-.04.369-.247.34-.466l-.019-.083-2.01-5.917-.032-.07c-.073-.13-.212-.215-.366-.215zm-.578 1.54h.018l.104.478.112.47.059.215.378 1.314H4.829l.416-1.417.096-.383.15-.676z"
            />
            <rect id="e267166sqa" width="42" height="42" x="0" y="0" rx="5" />
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g>
                <g>
                    <g>
                        <g>
                            <g>
                                <g
                                    opacity=".3"
                                    transform="translate(-553.000000, -729.000000) translate(548.000000, 40.000000) translate(0.000000, 536.000000) translate(0.000000, 144.000000) translate(5.000000, 9.000000)"
                                >
                                    <mask id="sawas7l4vb" fill="#fff">
                                        <use xlink:href="#e267166sqa" />
                                    </mask>
                                    <g fill-rule="nonzero" mask="url(#sawas7l4vb)">
                                        <path d="M0 0L42 0 42 42 0 42z" />
                                    </g>
                                </g>
                                <g
                                    transform="translate(-553.000000, -729.000000) translate(548.000000, 40.000000) translate(0.000000, 536.000000) translate(0.000000, 144.000000) translate(5.000000, 9.000000) translate(9.000000, 9.000000)"
                                >
                                    <mask id="erd7gzyt1e" fill="#fff">
                                        <use xlink:href="#ncq0lbubnd" />
                                    </mask>
                                    <g mask="url(#erd7gzyt1e)">
                                        <g>
                                            <path
                                                fill="#949D9E"
                                                fill-rule="nonzero"
                                                d="M0 0L42 0 42 42 0 42z"
                                                transform="translate(-9.000000, -9.000000)"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'TestIcon'
}
</script>

<style scoped></style>
