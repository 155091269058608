import { get, includes, assign } from 'lodash'
import { getters } from '@/store/modules/auth/getters'
import { Login } from '@/interfaces/auth'
import { User, Tenant, Organisation, Group } from '@/interfaces/resources'
import { AuthState } from '@/interfaces/state'
import { handleError } from '@/helpers/error'
import AuthService from '@/services/api/auth'
import i18n from '@/plugins/i18n'
import { getInitialAuthState } from '@/store/initialState'
import { sanctumInit } from '@/helpers/sanctum'
import TenantClass from '@/services/tenant'
import CancelToken from '@/services/api/cancelToken'
import router from '@/router'
import { TranslateResult } from 'vue-i18n'
import authService from '@/services/api/auth'

const authStore = {
    namespaced: true,
    state: getInitialAuthState(),
    mutations: {
        setUserLoggedInStatus(state: AuthState, status = false) {
            state.isUserLoggedIn = status
        },
        setLoggedInUser(state: AuthState, loggedInUser?: User) {
            if (loggedInUser) {
                state.loggedInUser = loggedInUser
                state.hasAddedOwnTenant = false
                state.hasAddedOwnOrganisation = false
                state.hasAddedOwnGroup = false

                return
            }

            state.loggedInUser = null
        },
        setUserTypes(state: AuthState, userTypes = []) {
            state.userTypes = userTypes
        },
        setInitialAuthState(state: AuthState) {
            assign(state, getInitialAuthState())
        },
        setTenant(state: AuthState, tenant?: Tenant) {
            if (state.loggedInUser) {
                state.hasAddedOwnTenant = !!tenant
                state.loggedInUser.tenant = tenant ? tenant : null
            }
        },
        setOrganisation(state: AuthState, organisation?: Organisation) {
            if (state.loggedInUser) {
                state.hasAddedOwnOrganisation = !!organisation
                state.loggedInUser.organisation = organisation ? organisation : null
            }
        },
        setGroup(state: AuthState, groups?: Group[]) {
            if (state.loggedInUser) {
                state.hasAddedOwnGroup = true
                state.loggedInUser.groups = groups ? groups : []
            }
        },
        setTerms(state: AuthState, termsAccepted = false) {
            if (state.loggedInUser) {
                state.loggedInUser.termsAccepted = termsAccepted
            }
        },
        setLoggingOutComplete(state: AuthState, loggingOutComplete = false) {
            state.loggingOutComplete = loggingOutComplete
        }
    },
    actions: {
        async login({ commit, dispatch }: any, { credentials }: { credentials: Login }) {
            try {
                const userResponse = await AuthService.login(credentials)

                if (get(userResponse, 'status') === 200) {
                    const loggedInUserData: User = get(userResponse, 'data.user_details')
                    const loggedInUserPermissions = loggedInUserData.permissions

                    if (loggedInUserPermissions && !includes(loggedInUserPermissions, 'login-dashboard')) {
                        await dispatch('logout')
                        commit('setValidationEvent', [`${i18n.t('store.webAppNoPermission')}`], { root: true })
                        return
                    }

                    commit('setUserLoggedInStatus', true)
                    commit('setLoggedInUser', loggedInUserData)
                    await dispatch('getLoggedInUserTenant')

                    await dispatch(
                        'userStore/getAssayDefinition',
                        {
                            assay_id: loggedInUserData.assay_id,
                            shouldCommit: true
                        },
                        { root: true }
                    )
                }
            } catch (error) {
                handleError(error as Error)
            }
        },
        async getLoggedInUserTenant({ commit, getters }: any) {
            const userTenant = getters.getTenant
            const tenant = TenantClass.getInstance()

            const providerPortal = tenant.getSlug() === 'transform'

            if (!providerPortal && !userTenant) {
                try {
                    const proxyTenantResponse = await AuthService.getTenant()

                    if (proxyTenantResponse.status === 200) {
                        const proxyTenantId = get(proxyTenantResponse, 'data.id')
                        commit('setTenant', { name: '', id: proxyTenantId })
                        commit('setUserTypes', {
                            displayName: 'Tenant',
                            name: 'tenant'
                        })
                    }
                } catch (error) {
                    handleError(error as Error)
                }
            }
        },
        async getUserTypes({ commit }: any) {
            try {
                const userTypesResponse = await AuthService.getUserTypes()

                if (userTypesResponse.status === 200) {
                    commit('setUserTypes', userTypesResponse.data)
                }
            } catch (error) {
                handleError(error as Error)
            }
        },
        async logout({ dispatch }: any) {
            try {
                await AuthService.logout()
            } catch (error) {
                handleError(error as Error)
            } finally {
                await dispatch('clearStore')
            }
        },
        async getLoggedInUserDetails({ commit }: any) {
            try {
                const response = await authService.getUserDetails()

                if (response?.status === 200) commit('setLoggedInUser', response?.data?.data)
            } catch (error) {
                handleError(error as Error)
            }
        },
        async logoutFrontEnd(
            { dispatch, commit }: any,
            notificationMessage: TranslateResult = `${i18n.t('authMiddleware.sessionExpired')}`
        ) {
            await dispatch('clearStore')
            CancelToken.getInstance().abortHttp()
            await router.replace({ name: 'login' }).catch(err => {})
            commit('setNotificationEvent', notificationMessage, { root: true })
        },
        async clearStore({ commit }: any) {
            commit('setInitialState', null, { root: true })
            commit('setInitialAuthState')
            commit('guiStore/setInitialGuiState', null, { root: true })
            commit('testRecordStore/setInitialTestState', null, { root: true })
            commit('groupStore/setInitialGroupState', null, { root: true })
            commit('organisationStore/setInitialOrgState', null, { root: true })
            commit('userStore/setInitialUserState', null, { root: true })
            commit('tenantStore/setInitialTenantState', null, { root: true })
            commit('inviteStore/setInitialInviteState', null, { root: true })
            await sanctumInit()
            commit('setLoggingOutComplete', true)
        }
    },
    getters: getters
}

export default authStore
