const patientRegistration = {
    en_GB: {
        joinNow: 'Join Now',
        acceptInvite:
            'To accept your invite and complete registration, you must first download the app on your device.',
        appOverview:
            '{serviceName} is a simple app for recording your lateral flow test results. Available for iOS and Android, anyone can download and join when sent an invite from an administrator.',
        appStore: 'App Store',
        mobileApps: 'Mobile apps',
        playStore: 'Play Store',
        receivedInvite: 'Received your invite?',
        redeemInvitePageTitle: 'Redeem Invite',
        redeemInvite: 'Received an invite? Redeem now',
        redeemInviteCredentials: 'Received an invite? Enter your credentials below to complete registration and join.',
        emailAddressVerified: 'Email Address Verified!',
        selPatientSuccess:
            'You have successfully verified your email address, and your new user account is all setup and ready.',
        selfPatientDownloadAdvice:
            'Available for iOS and Android, download the {serviceName} app on your preferred device and log in with your verified email and password.',
        welcomeTo: 'Welcome to {serviceName}',
        authenticationComplete: 'Authentication complete',
        accountSetup: 'Account Setup',
        accountSetupPrompt: 'Please enter your full name, and create a password to protect your user account.',
        accountSetupComplete: 'Account setup complete.'
    },
    en_US: {
        joinNow: 'Join Now',
        acceptInvite:
            'To accept your invite and complete registration, you must first download the app on your device.',
        appOverview:
            '{serviceName} is a simple app for recording your lateral flow test results. Available for iOS and Android, anyone can download and join when sent an invite from an administrator.',
        appStore: 'App Store',
        mobileApps: 'Mobile apps',
        playStore: 'Play Store',
        receivedInvite: 'Received your invite?',
        redeemInvitePageTitle: 'Redeem Invite',
        redeemInvite: 'Received an invite? Redeem now',
        redeemInviteCredentials: 'Received an invite? Enter your credentials below to complete registration and join.',
        emailAddressVerified: 'Email Address Verified!',
        selPatientSuccess:
            'You have successfully verified your email address, and your new user account is all setup and ready.',
        selfPatientDownloadAdvice:
            'Available for iOS and Android, download the {serviceName} app on your preferred device and log in with your verified email and password.',
        welcomeTo: 'Welcome to {serviceName}',
        authenticationComplete: 'Authentication complete',
        accountSetup: 'Account Setup',
        accountSetupPrompt: 'Please enter your full name, and create a password to protect your user account.',
        accountSetupComplete: 'Account setup complete.'
    },
    es_MX: {
        joinNow: 'Join Now',
        acceptInvite:
            'Para aceptar su invitación y registro completo, primero debe descargar la aplicación móvil en su dispositivo.',
        appOverview:
            '{serviceName} es una aplicación móvil simple para grabar sus resultados de prueba de flujo lateral. Disponible para iOS y Android, cualquier persona puede descargar y unirse cuando se envíe una invitación de un administrador.',
        appStore: 'App Store',
        mobileApps: 'Aplicaciones móviles',
        playStore: 'Play Store',
        receivedInvite: 'Recibió tu invitación?',
        redeemInvitePageTitle: 'Canjear invitación\n',
        redeemInvite: 'Recibiste una invitación? Canjear ahora',
        redeemInviteCredentials:
            'Recibiste una invitación? Ingrese sus credenciales a continuación para completar el registro y unirse.',
        emailAddressVerified: '¡Correo electrónico verificado!',
        selPatientSuccess:
            'Ha verificado con éxito su dirección de correo electrónico, y su nueva cuenta de usuario es la configuración y la lista.',
        selfPatientDownloadAdvice:
            'Disponible para iOS y Android, descargue la aplicación {serviceName} en su dispositivo preferido e inicie sesión con su correo electrónico y contraseña verificados.',
        welcomeTo: 'Bienvenido a {serviceName}',
        authenticationComplete: 'Autenticación completa',
        accountSetup: 'Configuracion de cuenta',
        accountSetupPrompt: 'Ingrese su nombre completo y cree una contraseña para proteger su cuenta de usuario.',
        accountSetupComplete: 'Se completó la configuración de la cuenta.'
    }
}

export default patientRegistration
