const password = {
    en_GB: {
        accountExistsEnd: ', an email will be sent with further instructions.',
        accountExistsStart: 'If a {appName} account exists for',
        changeMyPw: 'Change my password',
        changePw: 'Change password',
        createPwBtnText: 'Set password',
        createPwText: 'Please set a password to log in and protect your new account.',
        createPwTitle: 'Create a password',
        currentPw: 'Current password',
        emailVerified: 'Your email address has been verified.',
        emailVerifiedLogin: 'Email Address Verified! Please log in.',
        forgot: 'Forgot password?',
        newPw: 'New password',
        reset: 'Reset your password',
        resetInfo: "Enter your user account's verified email address and we will send you a password reset link.",
        resetPw: 'Reset Password',
        resetPwUser: 'User password reset',
        resetPwUserTextFirstPara: "As an administrator, you can reset users' passwords to maintain account security.",
        resetPwUserTextSecondPara:
            'The user will be automatically logged out of any active session, and will be sent an email with instructions and a link to change their password.',
        resetPwUserTitle: "Reset users' password",
        updatePwBtnText: 'Update',
        updatePwText:
            'The password associated with {email} was reset or has expired. Please set a new password to protect your account.',
        updatePwTitle: 'Update your password',
        changePasswordSettings: 'Change the password for your account.',
        enterCurrent: 'Enter your current password'
    },
    en_US: {
        accountExistsEnd: ', an email will be sent with further instructions.',
        accountExistsStart: 'If a {appName} account exists for',
        changeMyPw: 'Change my password',
        changePw: 'Change password',
        createPwBtnText: 'Set password',
        createPwText: 'Please set a password to log in and protect your new account.',
        createPwTitle: 'Create a password',
        currentPw: 'Current password',
        emailVerified: 'Your email address has been verified.',
        emailVerifiedLogin: 'Email Address Verified! Please log in.',
        forgot: 'Forgot password?',
        newPw: 'New password',
        reset: 'Reset your password',
        resetInfo: "Enter your user account's verified email address and we will send you a password reset link.",
        resetPw: 'Reset Password',
        resetPwUser: 'User password reset',
        resetPwUserTextFirstPara: "As an administrator, you can reset users' passwords to maintain account security.",
        resetPwUserTextSecondPara:
            'The user will be automatically logged out of any active session, and will be sent an email with instructions and a link to change their password.',
        resetPwUserTitle: "Reset users' password",
        updatePwBtnText: 'Update',
        updatePwText:
            'The password associated with {email} was reset or has expired. Please set a new password to protect your account.',
        updatePwTitle: 'Update your password',
        changePasswordSettings: 'Change the password for your account.',
        enterCurrent: 'Enter your current password'
    },
    es_MX: {
        accountExistsEnd: ', se enviará un correo electrónico con más instrucciones.',
        accountExistsStart: 'Si existe una cuenta {appName} para',
        changeMyPw: 'Cambia mi contraseña',
        changePw: 'Cambiar la contraseña',
        createPwBtnText: 'Configurar la clave',
        createPwText: 'Por favor, configure una contraseña para iniciar sesión y proteger su nueva cuenta.',
        createPwTitle: 'Crear una contraseña',
        currentPw: 'Contraseña actual',
        emailVerified: 'Tu dirección de email ha sido verificada.',
        emailVerifiedLogin: '¡Correo electrónico verificado! Por favor Iniciar sesión.',
        forgot: 'Se te olvidó tu contraseña?',
        newPw: 'Nueva contraseña',
        reset: 'Restablecer su contraseña',
        resetInfo: 'Restablecer su contraseña',
        resetPw: 'Restablecer la contraseña',
        resetPwUser: 'Restablecimiento de contraseña de usuario',
        resetPwUserTextFirstPara:
            'Como administrador, puede restablecer las contraseñas de los usuarios para mantener la seguridad de la cuenta.',
        resetPwUserTextSecondPara:
            'El usuario se desconectará automáticamente de cualquier sesión activa, y se le enviará un correo electrónico con instrucciones y un enlace para cambiar su contraseña.',
        resetPwUserTitle: 'Restablecer la contraseña de los usuarios',
        updatePwBtnText: 'Actualizar',
        updatePwText:
            'La contraseña asociada con {email} se restableció o caducó. Establezca una nueva contraseña para proteger su cuenta.',
        updatePwTitle: 'Actualiza tu contraseña',
        changePasswordSettings: 'Cambia la contraseña de tu cuenta.',
        enterCurrent: 'Escribir tu contraseña actual'
    }
}

export default password
