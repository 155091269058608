import { DateTime } from 'luxon'

interface State {
    filterDates: {
        to: string
        from: string
    }
    resultFilter: string
    isCustomDateRange: boolean
}

const filters = {
    mutations: {
        setFilterDates(
            state: State,
            filter: {
                to: string
                from: string
            }
        ) {
            state.filterDates = filter
        },
        resetFilterDates(state: State) {
            state.filterDates = {
                to: null,
                from: null
            }
        },
        setIsCustomDateRange(state: State, bool = false) {
            state.isCustomDateRange = bool
        },
        setResultFilter(state: State, result: string) {
            state.resultFilter = result
        },
        resetResultFilter(state: State) {
            state.resultFilter = null
        }
    },
    getters: {
        filterDates: (state: State) => {
            const { from, to } = state.filterDates
            return {
                from: from ? DateTime.fromISO(from).toFormat('yyyy-MM-dd') : null,
                to: to ? DateTime.fromISO(to).toFormat('yyyy-MM-dd') : null
            }
        },
        resultFilter: (state: State) => state.resultFilter,
        isCustomDateRange: state => state.isCustomDateRange
    }
}

export default filters
