<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="18"
        height="20"
        viewBox="0 0 18 20"
    >
        <defs>
            <path
                id="prefix__a_exit"
                d="M9 16.484c3.58 0 6.484-2.903 6.484-6.484 0-1.722-.673-3.335-1.854-4.54-.303-.309-.637-.587-.995-.83-.575-.39-.724-1.172-.334-1.747.39-.575 1.172-.725 1.747-.335.497.337.958.723 1.378 1.15C17.065 5.37 18 7.614 18 10c0 4.97-4.03 9-9 9s-9-4.03-9-9c0-2.387.935-4.63 2.574-6.301.42-.428.881-.814 1.378-1.15.575-.39 1.357-.241 1.747.334.39.575.24 1.357-.334 1.747-.358.243-.692.521-.995.83C3.19 6.665 2.516 8.278 2.516 10c0 3.58 2.903 6.484 6.484 6.484zM9 0c.693 0 1.255.562 1.255 1.255v6.49C10.255 8.438 9.693 9 9 9c-.693 0-1.255-.562-1.255-1.255v-6.49C7.745.562 8.307 0 9 0z"
            />
        </defs>
        <g fill="none" fill-rule="evenodd" transform="translate(0 .5)">
            <mask id="prefix__b_exit" fill="#fff">
                <use xlink:href="#prefix__a_exit" />
            </mask>
            <use fill="#979797" fill-rule="nonzero" xlink:href="#prefix__a_exit" />
            <g fill="currentColor" fill-rule="nonzero" mask="url(#prefix__b_exit)">
                <path d="M0 0L42 0 42 42 0 42z" transform="translate(-12 -10.5)" />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'ExitIcon'
}
</script>

<style scoped></style>
