const exportTest = {
    en_GB: {
        export: 'Export',
        exportModalTitle: 'Export test records?',
        yesExport: 'Yes, Export records',
        exportModalBody: 'Export and download a copy of test records data as a comma-separated values (CSV) file.',
        exportError: 'Unable to export data due to an unknown error. Please try again.',
        preparingExport: 'Preparing data export',
        successExport: 'Test records exported.'
    },
    en_US: {
        export: 'Export',
        exportModalTitle: 'Export test records?',
        yesExport: 'Yes, Export records',
        exportModalBody: 'Export and download a copy of test records data as a comma-separated values (CSV) file.',
        exportError: 'Unable to export data due to an unknown error. Please try again.',
        preparingExport: 'Preparing data export',
        successExport: 'Test records exported.'
    },
    es_MX: {
        export: 'Exportar',
        exportModalTitle: 'Exportar registros de prueba?',
        yesExport: 'Yes, Export records',
        exportModalBody:
            'Exporte y descargue una copia de los datos de los registros de prueba como un archivo de valores separados por comas (CSV).',
        exportError: 'No se pueden exportar los datos debido a un error desconocido. Inténtalo de nuevo.',
        preparingExport: 'Preparando la exportación de datos',
        successExport: 'Registros de prueba exportados.'
    }
}

export default exportTest
