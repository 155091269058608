import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import store from '@/store'
import BaseLayout from '@/modules/_global/views/layouts/BaseLayout.vue'
import groups from '@/modules/groups/routes/groups'
import invites from '@/modules/invites/routes/invites'
import organisations from '@/modules/organisations/routes/organisations'
import tenants from '@/modules/tenants/routes/tenants'
import tests from '@/modules/testRecords/routes/tests'
import users from '@/modules/users/routes/users'
import viewOwn from '@/modules/_global/routes/viewOwn'
import { get } from 'lodash'
import { getPageTitle } from '@/helpers/metaData'
import { handleNonLoggedInRoute } from '@/router/middleware/loggedInRoute'
import nonLoggedIn from '@/modules/_global/routes/nonLoggedIn'
import ignoreTermsAccepted from '@/modules/_global/routes/ignoreTermsAccepted'
import misc from '@/modules/_global/routes/misc'
import { permissionsCheck, featureFlagCheck } from '@/router/routeGuards'
import Tenant from '@/services/tenant'
import dashboard from '@/modules/dashboard/routes'
const tenant = Tenant.getInstance()

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: BaseLayout,
        meta: {
            loggedInRoute: true
        },
        children: [
            dashboard,
            ...tests,
            groups,
            invites,
            organisations,
            tenants,
            users,
            {
                path: '/create-test',
                name: 'createTest',
                component: () => import('@/modules/testRecords/views/create/Create.vue'),
                meta: {
                    pageTitle: 'generic.recordTest',
                    flags: { prevent: ['PREVENT_CLINICIAN_TESTING'] }
                },
                beforeEnter: (to, from, next) => {
                    const proceed =
                        permissionsCheck({ required: 'create-tests', store }) &&
                        featureFlagCheck({
                            preventedRouteFlags: to.meta.flags.prevent,
                            tenantFlags: tenant.getFeatureFlags(),
                            store
                        })
                    proceed ? next() : next('/')
                }
            }
        ].concat(viewOwn)
    }
].concat(nonLoggedIn, ignoreTermsAccepted, misc)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export async function beforeEach(to: Route, from: Route, next: any) {
    const isUserLoggedIn = store.getters['authStore/isUserLoggedIn']
    const termsAccepted = store.getters['authStore/getTerms']

    if (to.name === 'error') {
        next()
        return
    }

    if (get(to, 'params.clearFilter')) {
        store.commit('testRecordStore/resetFilterDates')
    }

    if (to.matched.some(record => record.meta.nonLoggedInRoute)) {
        return await handleNonLoggedInRoute(to, from, next, isUserLoggedIn)
    }

    if (to.matched.some(record => record.meta.loggedInRoute) && !isUserLoggedIn) {
        next({ path: '/login', query: { redirect: to.path } })
        return
    }

    if (
        !to.matched.some(record => record.meta.ignoreTermsAccepted) &&
        to.name !== 'dashboard' &&
        isUserLoggedIn &&
        !termsAccepted
    ) {
        next({ path: '/' })
        return
    }

    next()
}

router.beforeEach((to, from, next) => beforeEach(to, from, next))
router.afterEach((to, from) => {
    if (from.name !== to.name) {
        window.document.title = getPageTitle(to.name || '')
    }
})

router.onError(error => {
    if (/loading chunk [a-z,-d]* failed./i.test(error.message)) {
        window.location.reload()
    }
})

export default router
