






import { defineComponent, getCurrentInstance, computed } from '@vue/composition-api'
import { destructure } from '@/modules/_global/composables/destructure'

export default defineComponent({
    name: 'App',
    setup() {
        const { store } = destructure(getCurrentInstance)

        const appKey = computed(() => store.getters['getAppKey'])

        return {
            appKey
        }
    }
})
