import {
    State,
    AuthState,
    GroupState,
    UserState,
    OrganisationState,
    TenantState,
    TestRecordState,
    InviteState,
    GuiState
} from '@/interfaces/state'

export const getState = (): State => {
    return {
        locale: 'en_GB',
        notificationEvent: null,
        validationEvent: null,
        parentChildInputSelectedEvent: null,
        appKey: 0
    }
}

export const getInitialAuthState = (): AuthState => {
    return {
        isUserLoggedIn: false,
        loggedInUser: null,
        userTypes: [],
        hasAddedOwnTenant: false,
        hasAddedOwnOrganisation: false,
        hasAddedOwnGroup: false,
        loggingOutComplete: false
    }
}

export const getInitialGroupState = (): GroupState => {
    return {
        groups: [],
        group: null,
        isModalActionCompletedEvent: false
    }
}

export const getInitialTenantState = (): TenantState => {
    return {
        tenants: [],
        tenant: null
    }
}

export const getInitialOrgState = (): OrganisationState => {
    return {
        organisations: [],
        organisation: null,
        isModalActionCompletedEvent: false
    }
}

export const getInitialInviteState = (): InviteState => {
    return {
        invites: [],
        invite: null,
        isModalActionCompletedEvent: false
    }
}

export const getInitialTestRecordState = (): TestRecordState => {
    return {
        testRecords: [],
        testRecord: null,
        isModalActionCompletedEvent: false,
        filterDates: {
            to: null,
            from: null
        },
        resultFilter: null,
        isCustomDateRange: false
    }
}

export const getInitialUserState = (): UserState => {
    return {
        user: null,
        assayDefinition: null,
        isModalActionCompletedEvent: false
    }
}

export const getInitialGuiState = (): GuiState => {
    return {
        defaultRows: 5,
        navDrawerOpen: false,
        globalLoading: false,
        usCounties: []
    }
}
