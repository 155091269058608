import { NavigationGuardNext, Route } from 'vue-router'
import Tenant from '@/services/tenant'
const tenant = Tenant.getInstance()

const nonLoggedIn: any[] = [
    {
        path: '/patient-registration/:id?',
        name: 'patientRegistration',
        component: () => import('@/modules/_global/views/registration/PatientRegistration.vue'),
        meta: {
            nonLoggedInRoute: true
        },
        beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
            if (tenant.getFeatureFlags().includes('PREVENT_PATIENT_INVITES')) {
                next('/login')
                return
            }

            next()
        }
    },
    {
        path: '/account-setup',
        name: 'accountSetup',
        component: () => import('@/modules/_global/views/registration/AccountSetup.vue'),
        meta: {
            nonLoggedInRoute: true
        },
        props: true,
        beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
            if (!(to.params && to.params.newUser)) {
                next('/login')
                return
            }

            next()
        }
    },
    {
        path: '/self-registration',
        name: 'selfRegistration',
        component: () => import('@/modules/_global/views/registration/SelfRegistration.vue'),
        meta: {
            nonLoggedInRoute: true
        },
        beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
            if (tenant.getFeatureFlags().includes('PREVENT_SELF_REGISTRATION')) {
                next('/')
                return
            }

            next()
        }
    },
    {
        path: '/verify-new-self-registration',
        name: 'verifyNewSelfRegistration',
        meta: {
            nonLoggedInRoute: true
        }
    },
    {
        path: '/verify-new',
        name: 'verifyNewEmail',
        meta: {
            nonLoggedInRoute: true
        }
    },
    {
        path: '/verify',
        name: 'verify',
        meta: {
            nonLoggedInRoute: true
        }
    },
    {
        path: '/verification-status',
        name: 'verifyEmailStatus',
        component: () => import('@/modules/_global/views/auth/VerifyEmailStatus.vue'),
        meta: {
            nonLoggedInRoute: true
        },
        beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
            if (!(to.params && to.params.email)) {
                next('/login')
                return
            }

            next()
        }
    },
    {
        path: '/forgotten-password',
        name: 'password',
        component: () => import('@/modules/_global/views/auth/Password.vue'),
        meta: {
            nonLoggedInRoute: true
        },
        alias: '/create-password',
        props: true,
        beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
            if (!(to.query && to.query.token)) {
                next('/login')
                return
            }

            next()
        }
    },
    {
        path: '/password-reset',
        name: 'passwordReset',
        component: () => import('@/modules/_global/views/auth/ResetPassword.vue'),
        meta: {
            nonLoggedInRoute: true
        },
        props: true
    }
]

export default nonLoggedIn
