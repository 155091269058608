const deletion = {
    en_GB: {
        blankError: 'Blank recorded in error',
        confirmDeleteResource: 'Yes, Delete {resourceType}',
        createdInError: 'Created in error',
        dataEnteredIncorrectly: 'Data entered incorrectly',
        delete: 'Delete',
        deleteResource: 'Delete {resource}',
        deletionHint: 'You must provide a reason for deleting {thisThese} {record}.', // e.g. You must provide a reason for deleting {these} {records}.
        formTitle: 'Delete {resource} {record}', // e.g. Delete tenant records
        noLongerReq: 'No longer required',
        notificationDeleted: '{notification} deleted',
        other: 'Other',
        record: 'record',
        records: 'records',
        these: 'these',
        this: 'this'
    },
    en_US: {
        blankError: 'Blank recorded in error',
        confirmDeleteResource: 'Yes, Delete {resourceType}',
        createdInError: 'Created in error',
        dataEnteredIncorrectly: 'Data entered incorrectly',
        delete: 'Delete',
        deleteResource: 'Delete {resource}',
        deletionHint: 'You must provide a reason for deleting {thisThese} {record}.', // e.g. You must provide a reason for deleting {these} {records}.
        formTitle: 'Delete {resource} {record}', // e.g. Delete tenant records
        noLongerReq: 'No longer required',
        notificationDeleted: '{notification} deleted',
        other: 'Other',
        record: 'record',
        records: 'records',
        these: 'these',
        this: 'this'
    },
    es_MX: {
        blankError: 'En blanco grabado en error',
        confirmDeleteResource: 'Sí, eliminar {resourceType}',
        createdInError: 'Creado por error',
        dataEnteredIncorrectly: 'Los datos ingresados ​​incorrectamente',
        delete: 'Borrar',
        deleteResource: 'Eliminar {resource}',
        deletionHint: 'Debe proporcionar una razón para eliminar {thisThese} {record}.', // e.g. You must provide a reason for deleting {these} {records}.
        formTitle: 'Eliminar {resource} {record}', // e.g. Delete tenant records
        noLongerReq: 'Ya no es requerido',
        notificationDeleted: '{notification} eliminada',
        other: 'Otra',
        record: 'registro',
        records: 'registros',
        these: 'estas',
        this: 'esta'
    }
}

export default deletion
