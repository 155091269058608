import { Route } from 'vue-router'
import Tenant from '@/services/tenant'
const tenant = Tenant.getInstance()

const permission = {
    permission: 'view-invites'
}

const invites = {
    path: '/invites',
    name: 'invites',
    component: () => import('@/modules/invites/views/List.vue'),
    meta: { ...permission, pageTitle: 'generic.invites' },
    beforeEnter: (to: Route, from: Route, next: any) => {
        if (tenant.getFeatureFlags().includes('PREVENT_PATIENT_INVITES')) next('/')
        else next()
    }
}

export default invites
