const subjects = {
    en_GB: {
        subjectInfo: 'Test Subject Info',
        subjectInfoDesc: 'Test subject to be associated with this test record',
        subjectLookupDesc:
            'Please enter the email address and date of birth of the Test Subject you wish to associate with this test record',
        createNewSubject: 'Create a New Test Subject',
        noHumanSubjectFound:
            'Email not found. Edit the email address you entered or create a new test subject to associate with this test record.',
        noNonHumanSubjectFound:
            'Custom ID not found. Edit the custom IDs you entered or create a new test subject to associate with this test record.',
        emailHint: 'e.g. name@email.com'
    },
    en_US: {
        subjectInfo: 'Test Subject Info',
        subjectInfoDesc: 'Test subject to be associated with this test record',
        subjectLookupDesc:
            'Please enter the email address and date of birth of the Test Subject you wish to associate with this test record',
        createNewSubject: 'Create a New Test Subject',
        noHumanSubjectFound:
            'Email not found. Edit the email address you entered or create a new test subject to associate with this test record.',
        noNonHumanSubjectFound:
            'Custom ID not found. Edit the custom IDs you entered or create a new test subject to associate with this test record.',
        emailHint: 'e.g. name@email.com'
    },
    es_MX: {
        subjectInfo: 'Información del asunto',
        subjectInfoDesc: 'Asunto que se asociará con este registro de prueba',
        subjectLookupDesc:
            'Ingrese la dirección de correo electrónico y la fecha de nacimiento del Sujeto que desea asociar con este registro de prueba',
        createNewSubject: 'Crear un Sujeto Nuevo',
        noHumanSubjectFound:
            'El correo electrónico no encontrado. Edite la dirección de correo electrónico que ingresó o cree un nuevo asunto para asociarlo con este registro de prueba.',
        noNonHumanSubjectFound:
            'ID personalizado no encontrado. Edite los ID personalizados que ingresó o cree un nuevo sujeto para asociarlo con este registro de prueba.',
        emailHint: 'e.g. nome@email.com'
    }
}

export default subjects
