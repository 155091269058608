const permission = {
    permission: 'view-tests'
}

const tests = [
    {
        path: '/test-records',
        name: 'test-records',
        component: () => import('@/modules/testRecords/views/List.vue'),
        meta: { ...permission, pageTitle: 'generic.testRecords' },
        props: true,
        children: [
            {
                path: ':id',
                name: 'test-record',
                component: () => import('@/modules/testRecords/views/View.vue'),
                meta: { ...permission, pageTitle: 'generic.testRecord' }
            }
        ]
    },
    {
        path: '/tests',
        name: 'tests',
        redirect: { name: 'test-records' },
        props: true
    }
]

export default tests
