const permission = {
    permission: 'view-groups'
}

const groups = {
    path: '/groups',
    name: 'groups',
    component: () => import('@/modules/groups/views/List.vue'),
    meta: { ...permission, pageTitle: 'generic.groups' },
    children: [
        {
            path: ':id',
            name: 'group',
            component: () => import('@/modules/groups/views/View.vue'),
            meta: { ...permission, pageTitle: 'generic.group' }
        }
    ]
}

export default groups
