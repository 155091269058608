import store from '@/store'
import { get } from 'lodash'

export default (el: any, bindings: any) => {
    const action = bindings.arg

    if (!action) return

    if (store.getters['authStore/hasPermission'](action)) return

    if (get(bindings.modifiers, 'disabled')) {
        el.classList.remove('secondary--text')
        el.classList.add('disabled-link')
        return
    }

    el.style.display = 'none'
}
