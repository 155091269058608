/* eslint-disable no-irregular-whitespace */
const modals = {
    en_GB: {
        accountAction: "{action} {entity}'s account?",
        accStatus: 'Account status',
        adminBlock: 'As an administrator, you can block {entity} access to the system by suspending their accounts.',
        adminBlockNote: 'Note: After suspending {entity}, you can restore their accounts at any time.',
        adminRestore:
            'As an administrator, you can restore {entity} access to the system by reactivating their accounts.',
        adminRestoreAfter: 'After reactivating {entity}, they are no longer blocked from logging in to their accounts.',
        agreeBox: 'I have read and agree the terms of the end-user licence agreement.',
        agreeButton: 'Yes, I consent',
        userConfirmAction: 'Yes, {action} user',
        organisationConfirmAction: 'Yes, {action} organisation',
        groupConfirmAction: 'Yes, {action} group',
        confirmation: 'Confirmation',
        confirmReSend: 'Re-send {verificationType}',
        declineButton: 'No, I decline',
        deleteDescTxt: 'This will permanently remove {thisThese} {resourceType} {record} from the Transform system.', // e.g. You are about to delete {these} {tenant} {records} from...
        deleteDescTxtChild: 'This will permanently remove this {resourceType} from the Transform system.',
        deleteNumWarn: 'You are about to delete {num} {resourceType} {record}.', // e.g. You are about to delete {2} {user} {records}
        deleteNumWarnChild: 'You are about to delete {num} {resourceType}.', // e.g. You are about to delete {1} {user}
        eulaHeading: 'Consent',
        eulaLastUpdate: 'Last updated: {date}',
        reactivate: 'Reactivate',
        reactivated: 'reactivated',
        reSendConfirmation: 'Re-send account confirmation email',
        reSendVerification: 'Re-send email verification',
        userStatusNotification: 'User {status}', // e.g. User reactivated/suspended
        organisationStatusNotification: 'Organization {status}', // e.g. Org reactivated/suspended
        groupStatusNotification: 'Group {status}', // e.g. Group reactivated/suspended
        verification: 'Verification',
        verificationTypeBlock: '{verificationType} email will be sent to ',
        eulaText: `Please read this End-User License Agreement ("Agreement") carefully before clicking the "Agree and Continue" button, downloading or using The Application ("Application").\n
            By tapping the “Confirm” button, downloading or using the Application, you are agreeing to be bound by the terms and conditions of this Agreement.\n
            License\n
            The Company grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use the Application solely for your personal, non-commercial purposes strictly in accordance with the terms of this Agreement.\n
            Restrictions\n
            You agree not to, and you will not permit others to: license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the Application or make the Application available to any third party.\n
            Modifications to Application\n
            My Company (change this) reserves the right to modify, suspend or discontinue, temporarily or permanently, the Application or any service to which it connects, with or without notice and without liability to you.\n
            Term and Termination\n
            The company may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.\n
            This Agreement will terminate immediately, without prior notice from The Company, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the Application and all copies thereof from your mobile device or from your desktop.\n
            Upon termination of this Agreement, you shall cease all use of the Application and delete all copies of the Application.\n
            Severability\n
            If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.\n
            Amendments to this Agreement\n
            The company reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 (changes this) days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.`
    },
    en_US: {
        accountAction: "{action} {entity}'s account?",
        accStatus: 'Account status',
        adminBlock: 'As an administrator, you can block {entity} access to the system by suspending their accounts.',
        adminBlockNote: 'Note: After suspending {entity}, you can restore their accounts at any time.',
        adminRestore:
            'As an administrator, you can restore {entity} access to the system by reactivating their accounts.',
        adminRestoreAfter: 'After reactivating {entity}, they are no longer blocked from logging in to their accounts.',
        agreeBox: 'I have read and agree the terms of the end-user licence agreement.',
        agreeButton: 'Yes, I consent',
        userConfirmAction: 'Yes, {action} user',
        organisationConfirmAction: 'Yes, {action} organisation',
        groupConfirmAction: 'Yes, {action} group',
        confirmation: 'Confirmation',
        confirmReSend: 'Re-send {verificationType}',
        declineButton: 'No, I decline',
        deleteDescTxt: 'This will permanently remove {thisThese} {resourceType} {record} from the Transform system.', // e.g. You are about to delete {these} {tenant} {records} from...
        deleteDescTxtChild: 'This will permanently remove this {resourceType} from the Transform system.',
        deleteNumWarn: 'You are about to delete {num} {resourceType} {record}.', // e.g. You are about to delete {2} {user} {records}
        deleteNumWarnChild: 'You are about to delete {num} {resourceType}.', // e.g. You are about to delete {1} {user}
        eulaHeading: 'Consent',
        eulaLastUpdate: 'Last updated: {date}',
        reactivate: 'Reactivate',
        reactivated: 'reactivated',
        reSendConfirmation: 'Re-send account confirmation email',
        reSendVerification: 'Re-send email verification',
        userStatusNotification: 'User {status}', // e.g. User reactivated/suspended
        organisationStatusNotification: 'Organization {status}', // e.g. Org reactivated/suspended
        groupStatusNotification: 'Group {status}', // e.g. Group reactivated/suspended
        verification: 'Verification',
        verificationTypeBlock: '{verificationType} email will be sent to ',
        eulaText: `Please read this End-User License Agreement ("Agreement") carefully before clicking the "Agree and Continue" button, downloading or using The Application ("Application").\n
            By tapping the “Confirm” button, downloading or using the Application, you are agreeing to be bound by the terms and conditions of this Agreement.\n
            License\n
            The Company grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use the Application solely for your personal, non-commercial purposes strictly in accordance with the terms of this Agreement.\n
            Restrictions\n
            You agree not to, and you will not permit others to: license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the Application or make the Application available to any third party.\n
            Modifications to Application\n
            My Company (change this) reserves the right to modify, suspend or discontinue, temporarily or permanently, the Application or any service to which it connects, with or without notice and without liability to you.\n
            Term and Termination\n
            The company may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.\n
            This Agreement will terminate immediately, without prior notice from The Company, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the Application and all copies thereof from your mobile device or from your desktop.\n
            Upon termination of this Agreement, you shall cease all use of the Application and delete all copies of the Application.\n
            Severability\n
            If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.\n
            Amendments to this Agreement\n
            The company reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 (changes this) days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.`
    },
    es_MX: {
        accountAction: '{action} Cuenta del usuario?',
        accStatus: 'Estado de la cuenta',
        adminBlock: 'Como administrador, puede bloquear a los usuarios acceder al sistema suspendiendo sus cuentas.',
        adminBlockNote: 'NOTA: Después de suspender a los usuarios, puede restaurar sus cuentas en cualquier momento.',
        adminRestore: 'Como administrador, puede restaurar a los usuarios acceder al sistema reactivando sus cuentas.',
        adminRestoreAfter:
            'Después de reactivar a los usuarios, ya no están bloqueados desde el inicio de sesión en sus cuentas.',
        agreeBox: 'He leído y acepto los términos del acuerdo de licencia de usuario final',
        agreeButton: 'Si, doy mi consentimiento',
        confirmAction: 'Sí, {action} usuario',
        organisationConfirmAction: 'Sí, {action} organización',
        groupConfirmAction: 'Sí, {action} grupo',
        confirmation: 'Confirmación',
        confirmReSend: 'Re-enviar {verificationType}',
        declineButton: 'No, me niego',
        deleteDescTxt: 'Esto eliminará permanentemente {thisThese} {resourceType} {record} del sistema {tenant}.', // e.g. You are about to delete {these} {tenant} {records} from...
        deleteDescTxtChild: 'Esto eliminará permanentemente este {resourceType} del sistema {tenant}.',
        deleteNumWarn: 'Está a punto de eliminar {num} {resourceType} {record}.', // e.g. You are about to delete {2} {user} {records}
        deleteNumWarnChild: 'Está a punto de eliminar {num} {resourceType}.', // e.g. You are about to delete {1} {user}
        eulaHeading: 'Consentimiento',
        eulaLastUpdate: 'Última actualización: {date}',
        eulaText: `"Lea cuidadosamente este acuerdo de licencia de usuario final (""Acuerdo)) antes de hacer clic en el botón"" Acordar y continuar "", descargar o usar la aplicación ("" Aplicación ""). \n
        Al tocar el botón ""Confirmar"", descargar o usar la aplicación, usted acepta estar sujeto a los Términos y condiciones de este Acuerdo. \n
        LICENCIA \n
        La compañía le otorga una licencia revocable, no exclusiva, no transferible, limitada para descargar, instalar y usar la aplicación únicamente para sus fines personales, no comerciales estrictamente de acuerdo con los términos de este Acuerdo. \n
        Restricciones \n
        Usted acepta que no desea, y no permitirá a otros: licencia, vender, alquilar, arrendar, asignar, distribuir, transmitir, hospedar, subcontratar, divulgar o explotar de manera comercialmente la solicitud o hacer que la solicitud esté disponible para terceros. \n
        Modificaciones a la aplicación \n
        Mi compañía (Cambie esto) se reserva el derecho de modificar, suspender o interrumpir, de forma temporal o permanente, la aplicación o cualquier servicio al que conecta, con o sin previo aviso y sin responsabilidad. \n
        Término y terminación \n
        La compañía puede, a su entera discreción, en cualquier momento y, por alguna o ninguna razón, suspender o cancelar este acuerdo con o sin previo aviso. \n
        Este acuerdo terminará de inmediato, sin previo aviso de la Compañía, en el caso de que no cumpla con cualquier disposición de este Acuerdo. También puede cancelar este Acuerdo al eliminar la solicitud y todas las copias de su dispositivo móvil o desde su escritorio. \n
        Tras la terminación de este Acuerdo, cesará todo el uso de la solicitud y eliminará todas las copias de la solicitud. \n
        Severability \n
        Si se considera que alguna disposición de este Acuerdo es inaplicable o no válida, dicha disposición se modificará e interpretará para lograr los objetivos de dicha
        disposición en la mayor medida posible bajo la ley aplicable y las disposiciones restantes continuarán con plena fuerza y ​​efecto. \n
        Enmiendas a este Acuerdo \n
        La compañía se reserva el derecho, a su entera discreción, para modificar o reemplazar este acuerdo en cualquier momento. Si una revisión es material, proporcionaremos al menos 30 (cambios esto) Aviso de días antes de cualquier término nuevo que tenga efecto. Lo que constituye un cambio de material se determinará a nuestra entera discreción.`,
        reactivate: 'Reactivar',
        reactivated: 'reactivado',
        reSendConfirmation: 'Re-Enviar correo electrónico de confirmación de cuenta',
        reSendVerification: 'Volver a enviar la verificación de correo electrónico',
        userStatusNotification: 'Usuario {status}', // e.g. User reactivated/suspended
        organisationStatusNotification: 'Organización {status}', // e.g. Org reactivated/suspended
        groupStatusNotification: 'Grupo {status}', // e.g. Group reactivated/suspended
        verification: 'Verificación',
        verificationTypeBlock: '{verificationType} correo electrónico será enviado a'
    }
}

export default modals
