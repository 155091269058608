import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import Vue from 'vue'
import store from '@/store'
import { get } from 'lodash'

export const sentryInit = () => {
    const local = /bdh\.test/.test(window.location.hostname)
    const dev = process.env.NODE_ENV !== 'production'
    if (dev || local) return
    else {
        Sentry.init({
            Vue,
            dsn: 'https://fd603c4f95164b8887bb06fef2d390d2@o469638.ingest.sentry.io/5798611',
            integrations: [new Integrations.BrowserTracing()],
            environment: process.env.VUE_APP_TRANSFORM_ENV,
            release: 'Transform v' + Vue.prototype.$appVersion,
            ignoreErrors: [
                'top.GLOBALS',
                'Non-Error exception captured',
                'Set map center and zoom first',
                '_leaflet_pos',
                'Avoided redundant navigation to current location',
                'Navigation cancelled',
                'Redirected when going from',
                't is undefined'
            ],
            tracesSampleRate: 0.1
        })
        Sentry.configureScope(scope => {
            scope.setUser({
                id: get(store.getters['authStore/getLoggedInUser'], 'id', 'User not logged in'),
                email: get(store.getters['authStore/getLoggedInUser'], 'email', 'User not logged in')
            })
        })
    }
}
