













import { defineComponent, ref, getCurrentInstance, watch, computed } from '@vue/composition-api'
import { destructure } from '@/modules/_global/composables/destructure'

export default defineComponent({
    name: 'NotificationComponent',
    props: {
        isAbsolute: {
            type: Boolean,
            default: false
        },
        timeout: {
            type: Number,
            default: 4000
        }
    },
    setup() {
        const snackbar = ref(false)
        const displayText = ref('')
        const isClosable = ref(false)
        const { store } = destructure(getCurrentInstance)

        const notificationEvent = computed(() => {
            return store.getters['getNotificationEvent']
        })

        watch(
            notificationEvent,
            () => {
                if (notificationEvent.value) {
                    snackbar.value = true
                    displayText.value = notificationEvent.value
                }

                store.commit('setNotificationEvent')
            },
            { immediate: true }
        )

        const closeNotification = () => {
            snackbar.value = false
        }

        return {
            snackbar,
            displayText,
            isClosable,
            closeNotification,
            notificationEvent
        }
    }
})
