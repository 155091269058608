const invites = {
    en_GB: {
        btnNewInvite: 'Invite New Patient',
        inviteNewPatient: 'Invite a new Patient',
        enterEmail: "Enter patient's email address",
        enterPhoneNumber: '447123456789',
        inviteDesc: `Invite a new patient by entering both their email address
        and cellphone number. They'll be emailed a link, and must
        correctly authenticate their email and mobile details on the
        app to progress with registration`,
        emailHint: `Must be unique and not tied to an exisiting invite or user`,
        phoneHintUS: `Cellphone number`,
        phoneHintUK: `Mobile phone number`
    },
    en_US: {
        btnNewInvite: 'Invite New Patient',
        inviteNewPatient: 'Invite a new Patient',
        enterEmail: "Enter patient's email address",
        enterPhoneNumber: '447123456789',
        inviteDesc: `Invite a new patient by entering both their email address
        and cellphone number. They'll be emailed a link, and must
        correctly authenticate their email and mobile details on the
        app to progress with registration`,
        emailHint: `Must be unique and not tied to an exisiting invite or user`,
        phoneHintUS: `Cellphone number`,
        phoneHintUK: `Mobile phone number`
    },
    es_MX: {
        btnNewInvite: 'Invitar a nuevo paciente',
        inviteNewPatient: 'Invitar a un nuevo paciente',
        enterEmail: 'Ingrese la dirección de correo electrónico del paciente',
        enterPhoneNumber: '(555) 555-1234',
        inviteDesc: `Invite a un nuevo paciente ingresando su dirección de correo electrónico
        y número de teléfono móvil. Se les enviará un enlace por correo electrónico y deben
        autenticar correctamente su correo electrónico y datos móviles en el
        aplicación móvil para avanzar con el registro`,
        emailHint: `Debe ser único y no estar vinculado a una invitación o usuario existente.`,
        phoneHintUK: `Número de teléfono móvil en formato internacional`,
        phoneHintUS: `Número de teléfono móvil en formato internacional`
    }
}

export default invites
