import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import authStore from '@/store/modules/auth/store'
import groupStore from '@/store/modules/group/store'
import guiStore from '@/store/modules/gui'
import inviteStore from '@/store/modules/invite/store'
import organisationStore from '@/store/modules/organisation/store'
import tenantStore from '@/store/modules/tenant/store'
import testRecordStore from '@/store/modules/testRecord/store'
import userStore from '@/store/modules/user/store'
import { ParentChildSelectedElement } from '@/interfaces/auth'
import { assign, get } from 'lodash'
import i18n from '@/plugins/i18n'
import Vuetify from '@/plugins/vuetify'
import { getState } from '@/store/initialState'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: getState(),
    mutations: {
        setLocale(state, { locale }: any) {
            state.locale = locale
            i18n.locale = locale
        },
        setValidationEvent(state, error: string[] | null = null) {
            state.validationEvent = error
        },
        setNotificationEvent(state, notification: string | null = null) {
            state.notificationEvent = notification
        },
        incrementAppKey(state) {
            state.appKey++
        },
        setParentChildInputSelectedEvent(
            state,
            parentChildInputSelectedEvent: ParentChildSelectedElement | null = null
        ) {
            state.parentChildInputSelectedEvent = parentChildInputSelectedEvent
        },
        setInitialState(state: any) {
            assign(state, getState())
        }
    },
    getters: {
        getLocale: state => state.locale,
        getAppKey: state => state.appKey,
        getNotificationEvent: state => state.notificationEvent,
        getValidationEvent: state => state.validationEvent,
        getParentChildInputSelectedEvent: state => state.parentChildInputSelectedEvent
    },
    actions: {
        async changeLocale({ dispatch, commit }, { locale, userService, user }) {
            commit('setLocale', { locale })

            const vuetify = await Vuetify.getInstance()
            vuetify.framework.lang.current = locale.substring(0, 2)

            const assay_id = get(user, 'assay_id')
            await dispatch(
                'userStore/getAssayDefinition',
                {
                    assay_id,
                    shouldCommit: true
                },
                { root: true }
            )

            const userResponse = await userService.update(user.id, {
                lang: locale.substring(0, 2)
            })

            const updatedUser = get(userResponse, 'data.data')

            if (updatedUser) {
                await store.commit('authStore/setLoggedInUser', { ...updatedUser, tenant: user.tenant })
            }
        }
    },
    modules: {
        authStore,
        userStore,
        groupStore,
        inviteStore,
        tenantStore,
        organisationStore,
        testRecordStore,
        guiStore
    },
    plugins: [
        createPersistedState({
            storage: window.localStorage
        })
    ]
})

export default store
