const list = {
    en_GB: {
        loadingText: 'Loading {text}' // e.g. Loading Tenants
    },
    en_US: {
        loadingText: 'Loading {text}' // e.g. Loading Tenants
    },
    es_MX: {
        loadingText: 'Cargando {text}' // e.g. Loading Tenants
    }
}

export default list
