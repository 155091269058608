




import { destructure } from '@/modules/_global/composables/destructure'
import { computed, defineComponent, getCurrentInstance } from '@vue/composition-api'
import { get } from 'lodash'

export default defineComponent({
    name: 'PageTitle',
    setup() {
        const { route } = destructure(getCurrentInstance)
        const pageTitle = computed(() => {
            return get(route, 'meta.pageTitle')
        })

        return {
            pageTitle
        }
    }
})
