import Vue from 'vue'
import '@/scss/app.scss'
import App from './App.vue'
import store from './store'
import './helpers/rules.ts'
import { setInitialLocale } from './helpers/locale'
import router from './router'
import Vuetify from '@/plugins/vuetify'
import Can from '@/directives/can'
import { sentryInit } from '@/services/sentry'

import Crumbs from '@/modules/_global/components/misc/Crumbs.vue'
import ResultIcon from '@/modules/_global/components/misc/icons/Result.vue'
import TitleBar from '@/modules/_global/components/misc/TitleBar.vue'

import lineClamp from 'vue-line-clamp'
import ReadMore from 'vue-read-more'
import Donut from 'vue-css-donut-chart'
import 'vue-css-donut-chart/dist/vcdonut.css'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'

import VueCompositionAPI from '@vue/composition-api'
import i18n from './plugins/i18n'
import { isSupportedBrowser } from './helpers/environment'
import { sanctumInit } from '@/helpers/sanctum'

import Tenant from '@/services/tenant'

// eslint-disable-next-line
;(async () => {
    const supportedBrowser = isSupportedBrowser()
    await sanctumInit()

    // Setup tenant
    const tenant = Tenant.getInstance()
    await tenant.init()

    // Setup vuetify using tenant options
    const vuetify = await Vuetify.getInstance()

    if (supportedBrowser) {
        await setInitialLocale(store, vuetify, tenant)

        Vue.config.productionTip = false

        Vue.use(VueCompositionAPI)

        Vue.prototype.$appName = tenant.getProductName()
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        Vue.prototype.$appVersion = require('../package.json').version

        Vue.component('crumbs-bar', Crumbs)
        Vue.component('result-icon', ResultIcon)
        Vue.component('title-bar', TitleBar)

        Vue.directive('can', Can)

        Vue.use(lineClamp)
        Vue.use(ReadMore)
        Vue.use(Donut)

        sentryInit()

        new Vue({
            i18n,
            router,
            store,
            vuetify,
            render: h => h(App)
        }).$mount('#app')
    } else {
        document.getElementById('ie-warning').style['opacity'] = '1'
    }
})()
