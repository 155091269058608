const ignoreTermsAccepted: any[] = [
    {
        path: '/cookie-policy',
        name: 'cookiePolicy',
        component: () => import('@/modules/_global/views/policies/cookie.vue'),
        meta: {
            ignoreTermsAccepted: true
        }
    },
    {
        path: '/web-privacy-policy',
        name: 'webPrivacyPolicy',
        component: () => import('@/modules/_global/views/policies/webPrivacy.vue'),
        meta: {
            ignoreTermsAccepted: true
        }
    },
    {
        path: '/eula',
        name: 'eulaPolicy',
        component: () => import('@/modules/_global/views/policies/eula.vue'),
        meta: {
            ignoreTermsAccepted: true
        }
    },
    {
        path: '/app-privacy-policy',
        name: 'appPrivacyPolicy ',
        component: () => import('@/modules/_global/views/policies/appPrivacy.vue'),
        meta: {
            ignoreTermsAccepted: true
        }
    }
]

export default ignoreTermsAccepted
