





































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: 'BondLinks',
    props: {
        layoutClasses: {
            type: String,
            required: true
        },
        linkClasses: {
            type: String,
            required: true
        }
    },
    setup() {
        const bond = 'https://bondhealth.co.uk/'

        return {
            bond
        }
    }
})
