const error = {
    en_GB: {
        errorStatus400:
            'Sorry, the request sent by your browser was somehow malformed and so the server was unable to understand it.',
        errorStatus403:
            "You don't appear to have the necessary permissions to access the page or resource you requested on this server.",
        errorStatus404:
            'The page or resource you requested was not found. It may have been moved or it no longer exists on the system.',
        errorStatus500:
            "Sorry, a server or network error was encountered and your request couldn't be completed. Please reload this page now, or try again later.",
        goTo: 'Go to {0}',
        goToThe: 'Go to the {0}', // e.g. Go to the Dashboard
        httpError: 'HTTP error',
        testRecordSubmitError: 'No internet connection. Please try again.',
        testCreateErrorTitle: 'Unable to record a test',
        testCreateErrorBody:
            'Test records must be associated with an Organisation and a Group. At least one Organisation and Group must be setup on Transform in order to record tests.'
    },
    en_US: {
        errorStatus400:
            'Sorry, the request sent by your browser was somehow malformed and so the server was unable to understand it.',
        errorStatus403:
            "You don't appear to have the necessary permissions to access the page or resource you requested on this server.",
        errorStatus404:
            'The page or resource you requested was not found. It may have been moved or it no longer exists on the system.',
        errorStatus500:
            "Sorry, a server or network error was encountered and your request couldn't be completed. Please reload this page now, or try again later.",
        goTo: 'Go to {0}',
        goToThe: 'Go to the {0}', // e.g. Go to the Dashboard
        httpError: 'HTTP error',
        testRecordSubmitError: 'No internet connection. Please try again.',
        testCreateErrorTitle: 'Unable to record a test',
        testCreateErrorBody:
            'Test records must be associated with an Organisation and a Group. At least one Organisation and Group must be setup on Transform in order to record tests.'
    },
    es_MX: {
        errorStatus400:
            'Lo sentimos, la solicitud enviada por su navegador fue de alguna manera mal formada y, por lo tanto, el servidor no pudo entenderlo.',
        errorStatus403:
            'No parece que tiene los permisos necesarios para acceder a la página o el recurso que solicitó en este servidor.',
        errorStatus404:
            'La página o el recurso que solicitó no se encontró. Puede haberse movido o ya no existe en el sistema.',
        errorStatus500:
            'Lo sentimos, se encontró un servidor o error de red y su solicitud no se pudo completar. Por favor, vuelva a cargar esta página ahora, o intente nuevamente más tarde.',
        goTo: 'Ir a {0}',
        goToThe: 'Ir a la {0}', // e.g. Go to the Dashboard
        httpError: 'Error HTTP',
        testRecordSubmitError: 'Sin conexión a Internet. Inténtalo de nuevo.',
        testCreateErrorTitle: 'No se puede grabar una prueba',
        testCreateErrorBody:
            'Los registros de prueba deben estar asociados con una organización y un grupo. Se debe configurar al menos una organización y un grupo en Transform para poder registrar las pruebas.'
    }
}

export default error
