import { getEnvironment } from '@/helpers/environment'
import Tenant from '@/services/tenant'
import { startCase, kebabCase } from 'lodash'

export const getPageTitle = (pageName = '') => {
    const tenant = Tenant.getInstance()
    const env = getEnvironment()
    const appName = tenant.getProductName()
    const formattedPageName = startCase(kebabCase(pageName))

    if (env === 'production') return `${appName} - ${formattedPageName}`
    return `${appName} - ${startCase(env)} - ${formattedPageName}`
}
