import VueI18n from 'vue-i18n'
import messages from '@/locale/'
import Vue from 'vue'
import dateTimeFormats from '@/locale/dateTime'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'en_GB',
    fallbackLocale: ['en'],
    messages,
    dateTimeFormats
})

export default i18n
