import { DateTime } from 'luxon'
import i18n from '../plugins/i18n'
import Tenant from '@/services/tenant'

/**
 * TODO: Convert these into pure functions
 */

const tenant = Tenant.getInstance()
export const currentYear = DateTime.local().year

/**
 * Returns date formatted correctly for the current tenant
 * @param format
 * @param locale
 * @returns
 */

export const now = (format: 'long' | 'short' = 'long', locale: string) => {
    return format === 'short'
        ? DateTime.now().toFormat(tenant.getDateFormat('short'))
        : i18n.d(new Date(), format, locale.replace('_', '-'))
}

/**
 * Takes date, format (long or short) and locale as arguments and returns
 * the date in the tenants preferred format
 * @example formatDate('2021-12-22T10:30:00.664Z', 'short') //=> '12-12-2021'
 * @example formatDate('2021-12-22T10:30:00.664Z', 'long') //=> 'Sun 12th December 21'
 * @param date
 * @param format
 * @param locale
 * @returns
 */

export const formatDate = (
    date: string,
    format: 'long' | 'short' | 'date_time' | 'year' | 'monthYear' = 'short',
    locale = 'en_GB'
) => {
    if (!date) return ''
    if (format === 'short') return DateTime.fromISO(date).toFormat(tenant.getDateFormat('short'))
    if (format === 'year') return date
    if (format === 'monthYear') return i18n.d(new Date(date + '-15'), format, locale.replace('_', '-'))
    return i18n.d(new Date(date), format, locale.replace('_', '-'))
}

/**
 * takes a timestamp and return a time string formatted according to the format provided
 * @example formatTime('2021-12-22T10:30:00.664Z', 'HH:mm') //=> '10:30'
 * @param date
 * @param format
 */

export const formatTime = (date: string, format = 'HH:mm') => DateTime.fromISO(date).toFormat(format)

/**
 * if provided no arg is provided returns a timestamp set to the start of the current day.
 * if an arg is provided adds or subtracts that number of days and returns a timestamp
 * set to the start of that day
 * TODO: could be combined with startOfDay() into one function which takes "start" or "finish" as an arg
 * @param days
 * @returns
 */

export const subtractDays = (days = 0) => {
    return DateTime.now().minus({ days }).startOf('day').toISO()
}

/**
 * if provided no arg is provided returns a timestamp set to the start of the current day.
 * if an arg is provided adds or subtracts that number of months and returns a timestamp
 * TODO: could be combined with endOfDay() into one function which takes "start" or "finish" as an arg
 * @param days
 * @returns
 */

export const subtractMonths = (months = 0) => {
    return DateTime.now().minus({ months }).startOf('day').toISO()
}

/**
 * if provided no arg is provided returns a timestamp set to the start of the current day.
 * if an arg is provided adds or subtracts that number of days and returns a timestamp
 * set to the start of that day
 * TODO: could be combined with endOfDay() into one function which takes "start" or "finish" as an arg
 * @param days
 * @returns
 */

export const startOfDay = (days = 0) => {
    return DateTime.now().minus({ days }).startOf('day').toISO()
}

/**
 * if provided no arg is provided returns a timestamp set to the end of the current day.
 * if an arg is provided adds or subtracts that number of months and returns a timestamp
 * set to the end of that day
 * TODO: could be combined with startOfDay() into one function which takes "start" or "finish" as an arg
 * @param days
 * @returns
 */

export const endOfDay = (days = 0) => {
    return DateTime.now().minus({ days }).endOf('day').toISO()
}

/**
 * if provided no arg is provided returns a timestamp set to the start of the current month.
 * if an arg is provided adds or subtracts that number of months and returns a timestamp
 * set to the start of that month
 * TODO: could be refactored to combine endOfMonth() & subtractMonths() "start" or "finish" as an arg
 * @param days
 * @returns
 */

export const startOfMonth = (months = 0) => {
    return DateTime.now().minus({ months }).startOf('month').startOf('day').plus(1).toISO()
}

/**
 * if provided no arg is provided returns a timestamp set to the end of the current month.
 * if an arg is provided adds or subtracts that number of months and returns a timestamp
 * set to the end of that month
 * TODO: could be refactored to combine startOfMonth() & subtractMonths() "start" or "finish" as an arg
 * @param days
 * @returns
 */

export const endOfMonth = (months = 0) => {
    return DateTime.now().minus({ months }).endOf('month').endOf('day').toISO()
}

/**
 * returns a timestamp set to either the start of the day or the end
 * @param days
 * @returns
 */

export const convertCustomDate = (date: string, point: 'start' | 'end') => {
    const d = DateTime.fromISO(date)
    return point === 'start' ? d.startOf('day').toISO() : d.endOf('day').toISO()
}

/**
 * converts the "to" and "from" dates into timestamps with the "from"
 * being set to the start for the day and the "to" date set to the end of the day
 * @param dateFilter
 * @returns
 */
export const formatDateFilters = (dateFilter: { to: string | null; from: string | null }) => {
    return {
        to: dateFilter && dateFilter.to ? convertCustomDate(dateFilter.to, 'end') : null,
        from: dateFilter && dateFilter.from ? convertCustomDate(dateFilter.from, 'start') : null
    }
}

/**
 * converts the "to" and "from" dates into UTC timestamps
 * @param dateFilter
 * @returns
 */
export const convertDateFiltersToUTC = (dateFilter: { to: string | null; from: string | null }) => {
    return {
        to: new Date(dateFilter.to).toISOString(),
        from: new Date(dateFilter.from).toISOString()
    }
}
