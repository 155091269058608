






















































import { currentYear } from '@/helpers/dateTime'
import { computed, defineComponent, getCurrentInstance, watch } from '@vue/composition-api'
import { destructure } from '@/modules/_global/composables/destructure'
import BondLinks from '@/modules/_global/components/misc/BondLinks.vue'
import Navigation from '@/modules/_global/components/nav/Navigation.vue'
import Notification from '@/modules/_global/components/misc/Notification.vue'

export default defineComponent({
    name: 'BaseLayout',
    components: {
        Navigation,
        Notification,
        BondLinks
    },
    setup() {
        const { appVersion, appName, vuetify, store, router } = destructure(getCurrentInstance)
        const isLoggingOutComplete = computed(() => {
            return store.getters['authStore/getLoggingOutComplete']
        })

        const loading = computed(() => {
            return store.getters['guiStore/globalLoading']
        })

        watch(isLoggingOutComplete, async (newVal, oldVal) => {
            if (newVal) {
                await router.push({ name: 'loggedOut' }).catch(err => {})
                store.commit('authStore/setLoggingOutComplete')
            }
        })

        return {
            appName,
            appVersion,
            currentYear,
            loading,
            vuetify
        }
    }
})
