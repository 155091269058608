import { Organisation, Status } from '@/interfaces/resources'
import modal from '@/store/modules/modal'
import { assign } from 'lodash'
import { getInitialOrgState } from '@/store/initialState'
import http from '@/services/api/http'
import { handleError } from '@/helpers/error'

const organisationStore = {
    namespaced: true,
    state: getInitialOrgState(),
    mutations: {
        setSelectItems(state: any, organisations?: Organisation[]) {
            state.organisations = organisations || []
        },
        setItem(state: any, organisation?: Organisation) {
            state.organisation = organisation || null
        },
        setInitialOrgState(state: any) {
            assign(state, getInitialOrgState())
        },
        ...modal.mutations
    },
    getters: {
        getSelectItems: (state: any) => state.organisations,
        getItem: (state: any) => state.organisation,
        ...modal.getters
    },
    actions: {
        async updateStatus({ commit }: any, status: Status) {
            try {
                const response = await http.put(`organisations/${status.id}/${status.endpoint}`)

                if (response?.status === 200) {
                    commit('setItem', response?.data?.data)
                    commit('setSelectItems')
                }

                return response
            } catch (error) {
                handleError(error as Error)
            }
        }
    }
}

export default organisationStore
