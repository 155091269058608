const http = {
    en_GB: {
        401: 'Error 401: Unauthorized',
        403: 'Error 403: Forbidden',
        404: 'Error 404: Not Found'
    },
    en_US: {
        401: 'Error 401: Unauthorized',
        403: 'Error 403: Forbidden',
        404: 'Error 404: Not Found'
    },
    es_MX: {
        401: 'Error 401: No autorizado',
        403: 'Error 403: Prohibida',
        404: 'Error 404: No encontrada'
    }
}

export default http
