const permission = {
    permission: 'view-tenants'
}

const tenants = {
    path: '/tenants',
    name: 'tenants',
    component: () => import('@/modules/tenants/views/List.vue'),
    meta: { ...permission, pageTitle: 'generic.tenants' },
    children: [
        {
            path: ':id',
            name: 'tenant',
            component: () => import('@/modules/tenants/views/View.vue'),
            meta: { ...permission, pageTitle: 'generic.tenant' }
        }
    ]
}

export default tenants
