import router from '@/router'
import store from '@/store'
import i18n from '@/plugins/i18n'
import { errorPush } from '@/services/api/middleware/error'

export const handle404 = async (error: any) => {
    if (store.getters['authStore/isUserLoggedIn']) {
        await router.replace({ name: 'dashboard' }).catch(err => {})
        store.commit('setNotificationEvent', `${i18n.t('error.errorStatus404')}`)
        return
    }

    await errorPush(error)
}
