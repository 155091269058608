const authComposable = {
    en_GB: {
        loggedOut: 'You have successfully logged out'
    },
    en_US: {
        loggedOut: 'You have successfully logged out'
    },
    es_MX: {
        loggedOut: 'Has terminado tu sesion satisfactoriamente'
    }
}

export default authComposable
