import axios from 'axios'
import { handleAuth } from '@/services/api/middleware/auth'
import { get, includes } from 'lodash'
import { handleForm } from '@/services/api/middleware/form'
import { handle404 } from '@/services/api/middleware/404'
import * as AxiosLogger from 'axios-logger'
import { getLocalApiUrl } from '@/helpers/environment'
import { errorPush } from '@/services/api/middleware/error'

const hostname = window.location?.hostname

const devEnv = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test'
const apiVersion = process.env.VUE_APP_API_VERSION || 1

const http = axios.create({
    baseURL: devEnv ? `${getLocalApiUrl(hostname)}/api/v${apiVersion}` : `/api/v${apiVersion}`,
    withCredentials: true,
    headers: {
        'Content-type': 'application/json'
    }
})

if (devEnv) {
    http.interceptors.request.use(AxiosLogger.requestLogger)
}

http.interceptors.response.use(
    response => {
        return response
    },
    async error => {
        const url = get(error, 'response.config.url', '')
        const path = url.split('?')[0]
        const exemptions = ['/logout', '/verify-invite', '/subjects/lookup', '/test-records/export']

        if (includes(exemptions, path)) {
            return Promise.reject(error.response)
        }

        const status = get(error, 'response.status')
        if (error && status) {
            switch (status) {
                case 401: {
                    await handleAuth(error)
                    break
                }
                case 422: {
                    await handleForm(error)
                    break
                }
                case 404: {
                    await handle404(error)
                    break
                }
                default: {
                    await errorPush(error)
                }
            }

            return Promise.reject(error.response)
        }
    }
)

export default http
