import { Status } from '@/interfaces/resources'
import http from '@/services/api/http'
import { handleError } from '@/helpers/error'
import CancelToken from '@/services/api/cancelToken'

export const actions = {
    async updateStatus({ commit }: any, status: Status) {
        try {
            const response = await http.put(`users/${status.id}/${status.endpoint}`)

            if (response?.status === 200) commit('setItem', response?.data?.data)

            return response
        } catch (error) {
            handleError(error as Error)
        }
    },
    async getAssayDefinition({ commit }: any, { assay_id, shouldCommit }: { assay_id: string; shouldCommit: boolean }) {
        try {
            const response = await http.get(`/assays/${assay_id}/web`, {
                cancelToken: CancelToken.getInstance().getHttpToken()
            })

            if (response?.status === 200) {
                if (shouldCommit) commit('setAssayDefinition', response?.data?.data)

                return response?.data?.data
            }
        } catch (error) {
            handleError(error as Error)
        }
    }
}
