import http from '@/services/api/http'
import { AxiosResponse } from 'axios'
import { Login } from '@/interfaces/auth'
import store from '@/store'
import { getLocalApiUrl } from '@/helpers/environment'
import { get } from 'lodash'

const hostname = window.location?.hostname

export default {
    initialiseSanctum(): Promise<AxiosResponse> {
        const devEnv = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test'
        const sanctumUrl = devEnv
            ? `${getLocalApiUrl(hostname)}/sanctum/csrf-cookie`
            : `${window.location.protocol}//${window.location.hostname}/sanctum/csrf-cookie`

        return http.get(sanctumUrl)
    },
    login(credentials: Login): Promise<AxiosResponse> {
        return http.post('/login', credentials)
    },
    logout(): Promise<AxiosResponse> {
        return http.post('/logout')
    },
    getTenant(): Promise<AxiosResponse> {
        return http.get('/tenants/get-id')
    },
    buildUserTypeParams(): { lang: string; organisation_id?: string } {
        const pathname = get(window, 'location.pathname')
        const org = /\/organisations\//.test(pathname)
        return {
            lang: store.getters.getLocale || 'en_GB',
            ...(org && { organisation_id: pathname.replace('/organisations/', '') })
        }
    },
    selfRegister(params: any): Promise<AxiosResponse> {
        return http.post('/self-registration-user-create', params)
    },
    redeemInvite(params: any): Promise<AxiosResponse> {
        return http.get('/verify-invite', {
            params
        })
    },
    accountSetup(params: any): Promise<AxiosResponse> {
        return http.post('/invitee-update', params)
    },
    getUserTypes(): Promise<AxiosResponse> {
        const params = this.buildUserTypeParams()
        return http.get('/users/user-types', {
            params
        })
    },
    deleteAccount(params: any): Promise<AxiosResponse> {
        return http.put('/users/my-details/delete', params)
    },
    getUserDetails(): Promise<AxiosResponse> {
        return http.get('/users/my-details')
    }
}
