














































import { computed, ComputedRef, defineComponent, getCurrentInstance, ref, toRef, watch } from '@vue/composition-api'
import { last, split, values, map, slice, reverse } from 'lodash'
import { destructure } from '@/modules/_global/composables/destructure'
import { crumbs } from '@/modules/_global/composables/crumbs'

export default defineComponent({
    name: 'CrumbsComponent',
    props: {
        lastItem: String
    },
    setup(props) {
        const { route, i18n } = destructure(getCurrentInstance)
        const { getCrumbText } = crumbs()
        const menu = ref(false)
        const lastItemDisplay = toRef(props, 'lastItem')

        const getItems = () => {
            let pathArray = split(route.path, '/')
            pathArray.shift()
            const queryArray = values(route.query)
            pathArray = pathArray.concat(queryArray)

            return map(pathArray, (item, index) => {
                if (index === pathArray.length - 1 && lastItemDisplay.value) {
                    item = lastItemDisplay.value
                }

                if (!item) item = 'dashboard'

                if (item === 'test-records') item = 'tests'

                return {
                    disabled: index === pathArray.length - 1,
                    text: getCrumbText({ route, item, i18n }),
                    to: index !== pathArray.length - 1 ? item : ''
                }
            })
        }

        const items: ComputedRef<any> = computed(() => {
            return getItems()
        })

        const mobileItems = computed(() => {
            return reverse(slice(items.value, 0, items.value.length - 1))
        })

        const lastItemMobile = computed(() => {
            return last(items.value)
        })

        watch(lastItemDisplay, (newItem, oldItem) => {
            lastItemDisplay.value = newItem
        })

        return {
            menu,
            items,
            lastItemMobile,
            mobileItems
        }
    }
})
