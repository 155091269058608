<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 24 24"
    >
        <defs>
            <path id="spbav9upya" d="M0 0H24V24H0z" />
            <path
                id="qy4xzady9b"
                d="M12 17.984c3.58 0 6.484-2.903 6.484-6.484 0-1.722-.673-3.335-1.854-4.54-.303-.309-.637-.587-.995-.83-.575-.39-.724-1.172-.334-1.747.39-.575 1.172-.725 1.747-.335.497.337.958.723 1.378 1.15C20.065 6.87 21 9.114 21 11.5c0 4.97-4.03 9-9 9s-9-4.03-9-9c0-2.387.935-4.63 2.574-6.301.42-.428.881-.814 1.378-1.15.575-.39 1.357-.241 1.747.334.39.575.24 1.357-.334 1.747-.358.243-.692.521-.995.83C6.19 8.165 5.516 9.778 5.516 11.5c0 3.58 2.903 6.484 6.484 6.484zM12 1.5c.693 0 1.255.562 1.255 1.255v6.49c0 .693-.562 1.255-1.255 1.255-.693 0-1.255-.562-1.255-1.255v-6.49c0-.693.562-1.255 1.255-1.255z"
            />
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g>
                <g>
                    <g>
                        <g>
                            <g
                                transform="translate(-14 -1200) translate(0 1037.29) translate(0 144.71) translate(5 9) translate(9 9)"
                            >
                                <mask id="dkr3fsh81c" fill="#fff">
                                    <use xlink:href="#qy4xzady9b" />
                                </mask>
                                <g mask="url(#dkr3fsh81c)">
                                    <g>
                                        <path
                                            fill="currentColor"
                                            fill-rule="nonzero"
                                            d="M0 0L42 0 42 42 0 42z"
                                            transform="translate(-9 -9)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'LogoutIcon'
}
</script>

<style scoped></style>
