import router from '@/router'

export const errorPush = async error => {
    await router
        .replace({
            name: 'error',
            params: {
                error: error.response
            }
        })
        .catch(err => {})
}
