



















import { defineComponent } from '@vue/composition-api'
import Tenant from '@/services/tenant'
import { getEnvironment } from '@/helpers/environment'
export default defineComponent({
    name: 'AppDownload',
    props: {
        containerLayout: {
            type: String,
            default: 'd-flex flex-row align-center justify-around'
        },
        androidLayout: {
            type: Object,
            default: () => {
                return {
                    link: '',
                    img: ''
                }
            }
        },
        appleLayout: {
            type: Object,
            default: () => {
                return {
                    link: '',
                    img: ''
                }
            }
        }
    },
    setup() {
        const tenant = Tenant.getInstance()
        const storeLinks = tenant.getAppLinks()
        const env = getEnvironment()

        return { iosPath: storeLinks[env]?.ios, androidPath: storeLinks[env]?.android }
    }
})
