












































































import { computed, defineComponent, getCurrentInstance, onMounted, ref, watch } from '@vue/composition-api'
import { destructure } from '@/modules/_global/composables/destructure'
import { now } from '@/helpers/dateTime'
import { auth } from '@/modules/_global/composables/auth'
import AppDownload from '@/modules/_global/components/misc/AppDownload.vue'
import { UserTypes } from '@/enums/users'
import Tenant from '@/services/tenant'

export default defineComponent({
    name: 'NavigationWrapper',
    components: {
        AppDownload,
        NavLinks: () => import('@/modules/_global/components/nav/Links.vue'),
        NavActions: () => import('@/modules/_global/components/nav/Actions.vue'),
        ToolbarMenu: () => import('@/modules/_global/components/misc/ToolbarMenu.vue')
    },
    setup() {
        const { vuetify, store, i18n } = destructure(getCurrentInstance)
        const { canViewAppStoreDownLoads, hasPermission, userType } = auth(store)
        const drawer = ref(false)
        const icon = ref('$vuetify.icons.hamburger')
        const mini = ref(false)
        const opacity = 0.46
        const overlay = ref(false)
        const permanent = ref(false)
        const tenant = Tenant.getInstance()
        const { marque, logo } = tenant.getAssets()
        const zIndex = 7

        onMounted(async () => {
            if (vuetify.breakpoint.smAndUp && !vuetify.breakpoint.xlOnly) {
                mini.value = true
                store.commit('guiStore/setNavDrawerOpen', mini.value)
            }
        })

        const toggleDrawer = () => {
            if (vuetify.breakpoint.xsOnly) {
                drawer.value = !drawer.value
                return
            }

            mini.value = !mini.value
            store.commit('guiStore/setNavDrawerOpen', mini.value)
        }

        const onResize = () => {
            if (window.innerWidth < 768) {
                store.commit('guiStore/setNavDrawerOpen', mini.value)
            }
        }

        const toggleOverlay = (isActive: boolean) => {
            overlay.value = isActive
        }

        watch(drawer, () => {
            if (!drawer.value && vuetify.breakpoint.xsOnly) mini.value = false

            icon.value = drawer.value && !mini.value ? 'mdi-arrow-left' : '$vuetify.icons.hamburger'
        })

        watch(mini, () => {
            if (vuetify.breakpoint.xsOnly) return

            icon.value = mini.value ? '$vuetify.icons.hamburger' : 'mdi-arrow-left'
        })

        const currentDate = computed(() => {
            return now('long', i18n.locale)
        })

        const addNewButton = computed(() => {
            const preventWebTestCreation = tenant.getFeatureFlags().includes('PREVENT_WEB_TEST_CREATION')
            if (preventWebTestCreation) return false

            const preventClinicianTesting = tenant.getFeatureFlags().includes('PREVENT_CLINICIAN_TESTING')
            if (userType.value === UserTypes.Clinical && preventClinicianTesting) return false

            return hasPermission('create-tests')
        })

        return {
            addNewButton,
            canViewAppStoreDownLoads,
            currentDate,
            drawer,
            icon,
            logo,
            mini,
            marque,
            now,
            onResize,
            opacity,
            overlay,
            permanent,
            toggleDrawer,
            toggleOverlay,
            vuetify,
            zIndex
        }
    }
})
