import http from '@/services/api/http'
import { Status, Tenant } from '@/interfaces/resources'
import { handleError } from '@/helpers/error'
import { getInitialTenantState } from '@/store/initialState'
import { assign } from 'lodash'

const tenantStore = {
    namespaced: true,
    state: getInitialTenantState(),
    mutations: {
        setSelectItems(state: any, tenants?: Tenant[]) {
            state.tenants = tenants || []
        },
        setItem(state: any, tenant?: Tenant) {
            state.tenant = tenant || null
        },
        setInitialTenantState(state: any) {
            assign(state, getInitialTenantState())
        }
    },
    getters: {
        getSelectItems: (state: any) => state.tenants,
        getItem: (state: any) => state.tenant
    },
    actions: {
        async updateStatus({ commit }: any, status: Status) {
            try {
                const response = await http.put(`organisations/${status.id}/${status.endpoint}`)

                if (response?.status === 200) commit('setItem', response?.data?.data)

                return response
            } catch (error) {
                handleError(error as Error)
            }
        }
    }
}

export default tenantStore
