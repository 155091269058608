import AuthService from '@/services/api/auth'
import { handleError } from '@/helpers/error'

export const sanctumInit = async () => {
    try {
        await AuthService.initialiseSanctum()
    } catch (error) {
        handleError(error as Error)
    }
}
