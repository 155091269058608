import modal from '@/store/modules/modal'
import { AssayDefinition, User } from '@/interfaces/resources'
import { assign } from 'lodash'
import { getInitialUserState } from '@/store/initialState'

export const mutations = {
    setItem(state: any, user?: User) {
        state.user = user || null
    },
    setInitialUserState(state: any) {
        assign(state, getInitialUserState())
    },
    ...modal.mutations,
    setAssayDefinition(state: any, assayDefinition?: AssayDefinition) {
        state.assayDefinition = assayDefinition || null
    }
}
