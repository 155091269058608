const organisations = {
    en_GB: {
        addNewOrg: 'Add New Organization',
        addNpi: 'Enter National Provider Identifier',
        addOrgAddress: 'Add Organization Address',
        createOrganisation: 'Create a new organization',
        editOrg: 'Edit this organization',
        editOwnOrg: 'Edit my organization',
        enterCity: 'Enter city',
        enterCountry: 'Enter country',
        enterCounty: 'Enter county',
        enterStreetAddress: 'Enter street address',
        enterStreetAddress2: 'Enter street address 2',
        enterZip: 'Enter ZIP or postcode',
        hintNpi: 'The National Provider Identifier is a 10 digit number',
        orgDescription: 'Enter organization description',
        orgLocation: 'Enter organization location',
        orgName: 'Enter organization name',
        removeOrgAddress: 'Remove Organization Address'
    },
    en_US: {
        addNewOrg: 'Add New Organization',
        addNpi: 'Enter National Provider Identifier',
        addOrgAddress: 'Add Organization Address',
        createOrganisation: 'Create a new organization',
        editOrg: 'Edit this organization',
        editOwnOrg: 'Edit my organization',
        enterCity: 'Enter city',
        enterCountry: 'Enter country',
        enterCounty: 'Enter county',
        enterStreetAddress: 'Enter street address',
        enterStreetAddress2: 'Enter street address 2',
        enterZip: 'Enter ZIP or postcode',
        hintNpi: 'The National Provider Identifier is a 10 digit number',
        orgDescription: 'Enter organization description',
        orgLocation: 'Enter organization location',
        orgName: 'Enter organization name',
        removeOrgAddress: 'Remove Organization Address'
    },
    es_MX: {
        addNewOrg: 'Añadir nueva organización',
        addNpi: 'Ingrese el identificador del proveedor nacional',
        addOrgAddress: 'Agregar dirección de organización',
        createOrganisation: 'Crear una nueva organización',
        editOrg: 'Editar esta organización',
        editOwnOrg: 'Editar mi organización',
        enterCity: 'Ingrese ciudad',
        enterCountry: 'Ingrese el pais',
        enterCounty: 'Ingrese el condado',
        enterStreetAddress: 'Ingrese la dirección de la calle',
        enterStreetAddress2: 'Ingrese la dirección de la calle 2',
        enterZip: 'Ingrese zip o código postal',
        hintNpi: 'El identificador del proveedor nacional es un número de 10 dígitos.',
        orgDescription: 'Ingrese la descripción de la organización',
        orgLocation: 'Ingrese la ubicación de la organización',
        orgName: 'Ingrese el nombre de la organización',
        removeOrgAddress: 'Eliminar la dirección de la organización'
    }
}

export default organisations
