import router from '@/router'
import { computed } from '@vue/composition-api'
import i18n from '@/plugins/i18n'
import { get, map, filter, includes } from 'lodash'
import { UserTypes } from '@/enums/users'
import CancelToken from '@/services/api/cancelToken'
import AuthService from '@/services/api/auth'
import { handleError } from '@/helpers/error'
import { Route } from 'vue-router'

export function auth(store: any, route?: Route) {
    const loggedInUser = computed(() => store.getters['authStore/getLoggedInUser'])

    const isUserLoggedIn = computed(() => store.getters['authStore/isUserLoggedIn'])

    const userType = computed(() => loggedInUser.value?.userType?.name)

    const userTypeDisplayValue = computed(() => loggedInUser.value?.userType?.displayName)

    const isGroupAdmin = computed(() => userType.value === UserTypes.Group)

    const getGroup = computed(() => store.getters['authStore/getGroup'])

    const getGroupId = computed(() => getGroup.value?.id)

    const logoutVerification = async (email: string) => {
        await store.dispatch('authStore/logout')

        await router
            .push({
                name: 'verifyEmailStatus',
                params: { email: email }
            })
            .catch(err => {})
    }

    const logout = async () => {
        await store.dispatch('authStore/logout')
        CancelToken.getInstance().abortHttp()
        await router.push({ name: 'login' }).catch(err => {})
        store.commit('setNotificationEvent', `${i18n.t('authComposable.loggedOut')}`)
    }

    const login = async (email: string, password: string, loginNotification?: string) => {
        try {
            const sanctumResponse = await AuthService.initialiseSanctum()

            if (sanctumResponse?.status === 204) {
                const redirectRoute = get(router.currentRoute, 'query.redirect', '/')

                await store.dispatch('authStore/login', {
                    credentials: {
                        username: email,
                        password: password
                    }
                })

                if (isUserLoggedIn.value) {
                    await router.push({ path: redirectRoute }).catch(err => {})
                }

                if (loginNotification) store.commit('setNotificationEvent', loginNotification)
            }
        } catch (error) {
            handleError(error as Error)
        }

        return loggedInUser.value
    }

    const hasPermission = (permission: string) => {
        return store.getters['authStore/hasPermission'](permission)
    }

    const isOwnResource = (type: string, resource: { id: string }) => {
        if (type === 'user') {
            return loggedInUser.value?.id === resource.id
        }
        if (type === 'group') {
            return !!loggedInUser.value?.groups.find(g => g.id === resource.id)
        }
        return false
    }

    const canViewOwnResource = (type: string) => {
        const { permissions } = loggedInUser.value
        return permissions.includes(`view-own-${type}`)
    }

    const hasViewPermissions = (type: 'user' | 'subject' | 'group', resource: { id: string }) => {
        if (!loggedInUser.value) return false

        const { permissions } = loggedInUser.value

        if (!resource.id || resource.id === 'Anonymized') return false

        if (permissions.includes(`view-${type}s`)) return true

        const isOwn = isOwnResource(type, resource)
        const canViewOwn = canViewOwnResource(type)

        switch (type) {
            case 'user':
                return !!(isOwn && canViewOwn)
            case 'subject':
                return !!(isOwn && canViewOwn)
            case 'group':
                return !!(isOwn && canViewOwn)
            default:
                return false
        }
    }

    const isCurrentUser = (id: string) => {
        return loggedInUser.value?.id === id
    }

    const hasRelatedTenant = computed(() => {
        return get(loggedInUser.value, UserTypes.Tenant)
    })

    const getLoggedInUserAssayId = computed(() => {
        return get(loggedInUser.value, 'assay_id')
    })

    const getUserTypes = async (viewSpecificUserTypes?: string[]) => {
        await store.dispatch('authStore/getUserTypes')

        let userTypes = store.getters['authStore/getUserTypes']

        if (viewSpecificUserTypes) {
            userTypes = filter(userTypes, type => {
                return includes(viewSpecificUserTypes, type.value)
            })
        }

        return map(userTypes, (type: any) => {
            return {
                text: type.displayValue,
                value: type.value
            }
        })
    }

    const canViewAppStoreDownLoads = computed(() => {
        return userType.value === 'patient' || userType.value === 'clinical'
    })

    const canEditUser = computed(() => {
        if (userType.value === 'patient') return ['group', 'patient'].includes(userType.value)

        return hasPermission('edit-users')
    })

    const isOwnUserRoute = computed(() => route.name === 'ownUser')

    const isEditingSelf = computed(() => isOwnUserRoute.value || isCurrentUser(route.params?.id))

    const isUserType = (userTypes: UserTypes[]) => userTypes.includes(userType.value)

    return {
        canViewOwnResource,
        getGroupId,
        getLoggedInUserAssayId,
        getUserTypes,
        hasPermission,
        hasRelatedTenant,
        hasViewPermissions,
        isCurrentUser,
        isGroupAdmin,
        isOwnResource,
        isUserLoggedIn,
        loggedInUser,
        login,
        logout,
        logoutVerification,
        userType,
        canViewAppStoreDownLoads,
        canEditUser,
        isOwnUserRoute,
        isEditingSelf,
        userTypeDisplayValue,
        getGroup,
        isUserType
    }
}
