import { TestRecord } from '@/interfaces/resources'
import modal from '@/store/modules/modal'
import filters from '@/store/modules/filters'
import { assign } from 'lodash'
import { getInitialTestRecordState } from '@/store/initialState'

const testRecordStore = {
    namespaced: true,
    state: getInitialTestRecordState(),
    mutations: {
        setItems(state: any, testRecords?: TestRecord[]) {
            state.testRecords = testRecords || []
        },
        setItem(state: any, testRecord?: TestRecord) {
            state.testRecord = testRecord || null
        },
        setInitialTestState(state: any) {
            assign(state, getInitialTestRecordState())
        },
        ...modal.mutations,
        ...filters.mutations
    },
    getters: {
        getItems: (state: any) => state.testRecords,
        getItem: (state: any) => state.testRecord,
        ...modal.getters,
        ...filters.getters
    }
}
export default testRecordStore
