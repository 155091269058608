import { endsWith, get, startsWith } from 'lodash'
import store from '@/store'
import { errorPush } from '@/services/api/middleware/error'

export const handleAuth = async (error: any) => {
    if (store.getters['authStore/isUserLoggedIn']) {
        await store.dispatch('authStore/logoutFrontEnd')
        return
    }

    const errorUrl = get(error, 'response.config.url')

    if (endsWith(errorUrl, 'sanctum/csrf-cookie') || startsWith(errorUrl, 'verify')) {
        await errorPush(error)
        return
    }

    const errorObject = get(error, 'response.data.error')
    const message = get(errorObject, 'message')

    if (message) store.commit('setValidationEvent', [message])
}
