import Vue from 'vue'
import Base from 'vuetify/lib'
import Clinician from '@/modules/_global/components/icons/Clinician.vue'
import Dashboard from '@/modules/_global/components/icons/Dashboard.vue'
import Exit from '@/modules/_global/components/icons/Exit.vue'
import Group from '@/modules/_global/components/icons/Group.vue'
import Hamburger from '@/modules/_global/components/icons/Hamburger.vue'
import Invites from '@/modules/_global/components/icons/Invites.vue'
import Language from '@/modules/_global/components/icons/Language.vue'
import Logout from '@/modules/_global/components/icons/Logout.vue'
import Organisation from '@/modules/_global/components/icons/Organisation.vue'
import Patients from '@/modules/_global/components/icons/Patients.vue'
import Tenant from '@/modules/_global/components/icons/Tenant.vue'
import Test from '@/modules/_global/components/icons/Test.vue'
import CreateTest from '@/modules/_global/components/icons/CreateTest.vue'
import Invite from '@/modules/_global/components/icons/Invite.vue'
import Export from '@/modules/_global/components/icons/Export.vue'

import es from 'vuetify/src/locale/es'

import TenantClass from '@/services/tenant'

const getArgs = async (tenantSpecificColors: any) => {
    return {
        lang: {
            locales: { es },
            current: 'en'
        },
        breakpoint: {
            thresholds: {
                xs: 768,
                sm: 1024,
                md: 1440,
                lg: 1920
            }
        },
        theme: {
            themes: {
                light: {
                    textBlack: '#00262D',
                    textGray: '#748283',
                    textWhite: '#FFFFFF',
                    neutrals: {
                        base: '#fff',
                        darken1: '#f1f4f5',
                        darken2: '#c1ced2',
                        darken3: '#949d9e',
                        darken4: '#00262d'
                    },
                    error: {
                        base: '#ff4d4f',
                        darken2: '#AE393F'
                    },
                    warning: {
                        base: '#f59600',
                        darken2: '#a46a1f'
                    },
                    resultsPositive: '#5B9E4D',
                    resultsPositiveLight: '#B7D3B2',
                    resultsNegative: '#DE5833',
                    resultsNegativeLight: '#EDB6A7',
                    resultsInconclusive: '#F1A031',
                    resultsInconclusiveLight: '#F5D4A6',
                    resultsInvalid: '#686F9E',
                    resultsInvalidLight: '#D1D3E1',
                    semantic_highlight: '#0D66D0',
                    semanticHighlight_light: '#CFE0F6',
                    semanticInfo: '#1DB0E1',
                    semanticInfoLight: '#C6E6F1',
                    semanticWarning: '#F59600',
                    semanticWarningLight: '#FDEACC',
                    semanticSuccess: '#41B400',
                    semanticSuccessLight: '#D9F0CC',
                    semanticError: '#FF4D4F',
                    semanticErrorLight: '#FFDBDC',
                    ...tenantSpecificColors
                }
            },
            options: {
                customProperties: true
            }
        },
        icons: {
            values: {
                dashboard: {
                    component: Dashboard
                },
                test: {
                    component: Test
                },
                clinician: {
                    component: Clinician
                },
                group: {
                    component: Group
                },
                groupList: {
                    component: Group
                },
                groupView: {
                    component: Group
                },
                patients: {
                    component: Patients
                },
                patientView: {
                    component: Patients
                },
                exit: {
                    component: Exit
                },
                hamburger: {
                    component: Hamburger
                },
                createTest: {
                    component: CreateTest
                },
                tenant: {
                    component: Tenant
                },
                tenantView: {
                    component: Tenant
                },
                tenantList: {
                    component: Tenant
                },
                organisation: {
                    component: Organisation
                },
                organisationView: {
                    component: Organisation
                },
                organisationList: {
                    component: Organisation
                },
                logout: {
                    component: Logout
                },
                language: {
                    component: Language
                },
                invites: {
                    component: Invites
                },
                invite: {
                    component: Invite
                },
                export: {
                    component: Export
                }
            }
        }
    }
}

export default class Vuetify extends Base {
    private static instance: Vuetify

    constructor(args) {
        super(args)
    }

    public static async getInstance(): Promise<Vuetify> {
        if (!Vuetify.instance) {
            const tenant = TenantClass.getInstance()
            const theme = tenant.getTheme()
            const args = await getArgs(theme)
            Vuetify.instance = new Vuetify(args)
            Vue.use(Vuetify)
        }

        return Vuetify.instance
    }
}
