import { Route } from 'vue-router'
import store from '@/store'
import authService from '@/services/api/auth'
import { handleError } from '@/helpers/error'
import { handleVerification } from '@/router/middleware/verification'
import { AxiosResponse } from 'axios'
import { get } from 'lodash'
import i18n from '@/plugins/i18n'

const verifyNewEmailCallback = (next: any, response: AxiosResponse) => {
    const query = {
        token: get(response.data.data, 'token'),
        email: get(response.data.data, 'email')
    }

    next({
        path: '/create-password',
        params: {},
        query: query
    })
}

export const handleNonLoggedInRoute = async (to: Route, from: Route, next: any, isUserLoggedIn = false) => {
    if (isUserLoggedIn) {
        await store.dispatch('authStore/logout')
    }

    try {
        const sanctumResponse = await authService.initialiseSanctum()

        if (sanctumResponse.status === 204) {
            if (to.name === 'verifyNewEmail') {
                await handleVerification({ to, next, callback: verifyNewEmailCallback })
                return
            }

            if (to.name === 'verify' || to.name === 'verifyNewSelfRegistration') {
                const notification =
                    to.name === 'verify' ? 'authMiddleware.accountVerified' : 'password.emailVerifiedLogin'
                await handleVerification({ to, next, notification: `${i18n.t(notification)}` })
                return
            }

            next()
        }
    } catch (error) {
        handleError(error as Error)
    }
}
