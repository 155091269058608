import { Group, Status } from '@/interfaces/resources'
import modal from '@/store/modules/modal'
import { assign } from 'lodash'
import { getInitialGroupState } from '@/store/initialState'
import http from '@/services/api/http'
import { handleError } from '@/helpers/error'

const groupStore = {
    namespaced: true,
    state: getInitialGroupState(),
    mutations: {
        setSelectItems(state: any, groups?: Group[]) {
            state.groups = groups || []
        },
        setItem(state: any, group?: Group) {
            state.group = group || null
        },
        setInitialGroupState(state: any) {
            assign(state, getInitialGroupState())
        },
        ...modal.mutations
    },
    getters: {
        getSelectItems: (state: any) => state.groups,
        getItem: (state: any) => state.group,
        ...modal.getters
    },
    actions: {
        async updateStatus({ commit }: any, status: Status) {
            try {
                const response = await http.put(`groups/${status.id}/${status.endpoint}`)

                if (response?.status === 200) {
                    commit('setItem', response?.data?.data)
                    commit('setSelectItems')
                }

                return response
            } catch (error) {
                handleError(error as Error)
            }
        }
    }
}

export default groupStore
