const permission = {
    permission: 'view-users'
}

const users = {
    path: '/users',
    name: 'users',
    component: () => import('@/modules/users/views/List.vue'),
    meta: { ...permission, pageTitle: 'generic.users' },
    children: [
        {
            path: ':id',
            name: 'user',
            component: () => import('@/modules/users/views/View.vue'),
            meta: { ...permission, pageTitle: 'generic.user' }
        }
    ]
}

export default users
