import { upperFirst, get } from 'lodash'

export function crumbs() {
    const translateCrumbText = (string: string, i18n: { t: any }) => {
        switch (string) {
            case 'dashboard':
                return `${i18n.t('generic.dashboardLower')}`
            case 'organisations':
                return `${i18n.t('generic.organisationsLower')}`
            case 'groups':
                return `${i18n.t('generic.groupsLower')}`
            case 'users':
                return `${i18n.t('generic.usersLower')}`
            case 'tests':
                return `${i18n.t('generic.testsLower')}`
            case 'tenants':
                return `${i18n.t('generic.tenantsLower')}`
            default:
                return string
        }
    }

    /**
     * allows us to modify each crumb item based on route and/or regex
     * @param object
     * @returns a string to be used as part of the breadcrumb
     */
    const getCrumbText = ({ route, item, i18n }) => {
        const crumb = get(route, 'params.crumb')
        const isEmail = /.+@.+\..+/.test(item)
        const isUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(item)

        if (crumb && isUUID) return crumb

        if (isUUID) return item

        return isEmail ? item : upperFirst(translateCrumbText(item, i18n))
    }

    return {
        getCrumbText
    }
}
