import authComposable from './composables/auth'
import authMiddleware from './services/api/middleware/auth'
import countries from './countries'
import crud from './composables/crud'
import deletion from './composables/deletion'
import error from './views/error'
import filtering from './filtering'
import generic from './generic'
import groups from './components/groups'
import http from './services/api/http'
import input from './types/input'
import invites from './components/invites'
import layouts from './views/layouts/layouts'
import list from './composables/list'
import login from './views/auth/login'
import misc from './components/misc'
import modals from './components/modals'
import organisations from './components/organisations'
import password from '@/locale/password'
import patientRegistration from './views/patientRegistration'
import rules from './helpers/rules'
import selfRegistration from '@/locale/views/selfRegistration'
import store from './store/store'
import subjects from './subjects'
import testRecords from './composables/testRecords'
import validation from './validation'
import users from './components/users'
import exportTest from '@/locale/composables/export'

const messages = {
    en_GB: {
        authComposable: authComposable.en_GB,
        authMiddleware: authMiddleware.en_GB,
        countries: countries.en_GB,
        crud: crud.en_GB,
        deletion: deletion.en_GB,
        error: error.en_GB,
        filtering: filtering.en_GB,
        generic: generic.en_GB,
        groups: groups.en_GB,
        http: http.en_GB,
        input: input.en_GB,
        invites: invites.en_GB,
        layouts: layouts.en_GB,
        list: list.en_GB,
        login: login.en_GB,
        misc: misc.en_GB,
        modals: modals.en_GB,
        subjects: subjects.en_GB,
        organisations: organisations.en_GB,
        password: password.en_GB,
        patientRegistration: patientRegistration.en_GB,
        rules: rules.en_GB,
        selfRegistration: selfRegistration.en_GB,
        store: store.en_GB,
        testRecords: testRecords.en_GB,
        users: users.en_GB,
        validation: validation.en_GB,
        exportTest: exportTest.en_GB
    },
    en_US: {
        authComposable: authComposable.en_US,
        authMiddleware: authMiddleware.en_US,
        countries: countries.en_US,
        crud: crud.en_US,
        deletion: deletion.en_US,
        error: error.en_US,
        filtering: filtering.en_US,
        generic: generic.en_US,
        groups: groups.en_US,
        http: http.en_US,
        input: input.en_US,
        invites: invites.en_US,
        layouts: layouts.en_US,
        list: list.en_US,
        login: login.en_US,
        misc: misc.en_US,
        modals: modals.en_US,
        subjects: subjects.en_US,
        organisations: organisations.en_US,
        password: password.en_US,
        patientRegistration: patientRegistration.en_US,
        rules: rules.en_US,
        selfRegistration: selfRegistration.en_US,
        store: store.en_US,
        testRecords: testRecords.en_US,
        users: users.en_US,
        validation: validation.en_US,
        exportTest: exportTest.en_US
    },
    es_MX: {
        authComposable: authComposable.es_MX,
        authMiddleware: authMiddleware.es_MX,
        countries: countries.es_MX,
        crud: crud.es_MX,
        deletion: deletion.es_MX,
        error: error.es_MX,
        subjects: subjects.es_MX,
        filtering: filtering.es_MX,
        generic: generic.es_MX,
        groups: groups.es_MX,
        http: http.es_MX,
        input: input.es_MX,
        invites: invites.es_MX,
        layouts: layouts.es_MX,
        list: list.es_MX,
        login: login.es_MX,
        misc: misc.es_MX,
        modals: modals.es_MX,
        organisations: organisations.es_MX,
        password: password.es_MX,
        patientRegistration: patientRegistration.es_MX,
        rules: rules.es_MX,
        selfRegistration: selfRegistration.es_MX,
        store: store.es_MX,
        testRecords: testRecords.es_MX,
        users: users.es_MX,
        validation: validation.es_MX,
        exportTest: exportTest.es_MX
    }
}

export default messages
