import { first, get } from 'lodash'
import modal from '@/store/modules/modal'

export const getters = {
    getItem: (state: any) => state.user,
    getGroups: state => get(state.user, 'groups', []),
    getGroup: (state, getters: any) => {
        if (getters.getGroups.length) return first(getters.getGroups)
    },
    getOrganisation: state => get(state.user, 'organisation'),
    getTenant: state => get(state.user, 'tenant'),
    assayDefinition: state => get(state, 'assayDefinition'),
    ...modal.getters
}
