const viewOwn: any[] = [
    {
        path: '/own-user',
        name: 'ownUser',
        component: () => import('@/modules/users/views/View.vue'),
        meta: {
            permission: 'view-own-user'
        }
    },
    {
        path: '/own-organisation',
        name: 'ownOrganisation',
        component: () => import('@/modules/organisations/views/View.vue'),
        props: true,
        meta: {
            permission: 'view-own-org'
        }
    },
    {
        path: '/own-tenant',
        name: 'ownTenant',
        component: () => import('@/modules/tenants/views/View.vue'),
        props: true,
        meta: {
            permission: 'view-own-tenant'
        }
    },
    {
        path: '/own-group',
        name: 'ownGroup',
        component: () => import('@/modules/groups/views/View.vue'),
        props: true,
        meta: {
            permission: 'view-own-group'
        }
    }
]

export default viewOwn
