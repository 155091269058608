import { getters } from '@/store/modules/user/getters'
import { actions } from '@/store/modules/user/actions'
import { mutations } from '@/store/modules/user/mutations'
import { getInitialUserState } from '@/store/initialState'

const userStore = {
    namespaced: true,
    state: getInitialUserState(),
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default userStore
